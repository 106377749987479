import React,{Component} from "react";
import {DateRangePicker} from 'react-dates';
import moment from 'moment';
import DatePicker from "../../Form/Controls/DatePicker";
import DropDown from "../../Form/Controls/DropDown";
import { connect } from "react-redux";
import Input from "../../Form/Controls/Input";
import HorizontalRule from "../../Elements/HorizontalRule";
import DynamicForm from "../../Form/Controls/DynamicForm";
import DateRangeOptions from "./DateRangeOptions";
import OptionalField from "../../Form/Controls/OptionalField";
import Cookies from "js-cookie";


class Spend extends Component{

    constructor(props) {
        super(props);

        this.state = {

          options:["Between Prices","Greater Than Price","Less Than Price"]

        };

    }

    render() {

        return (
            <>

                    <h5>Sales at {this.props.merchantName}</h5>
                    <div className={"row"}>
                        <div className={"col"}>
                            <DateRangeOptions name={"merchantSpendDateOptions"} parentname={this.props.parentname}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-2 mg-t-10 mg-lg-t-0">
                            <DropDown

                                labelText={"Type"}
                                options={this.state.options}
                                name={"merchSpendRangeOption"}
                                className={"form-control"}
                                parentname={"targetingForm"}


                            />
                        </div>
                        <OptionalField targetName={"merchSpendRangeOption"} targetValue={"Between Prices"} parentname={this.props.parentname}>

                        <div className="col-lg-3 mg-t-10 mg-lg-t-0">
                            <Input
                                type={"number"}
                                labelText={"Minimum Price"}
                                name={"merchSpendMin"}
                                placeholder={"$"}
                                className={"form-control"}
                                number={"true"}
                                parentname={"targetingForm"}
                                step={0.01}

                            />
                        </div>
                        <div className="col-lg-3 mg-t-10 mg-lg-t-0">
                            <Input
                                type={"number"}
                                labelText={"Maximum Price"}
                                name={"merchSpendMax"}
                                placeholder={"$"}
                                className={"form-control"}
                                number={"true"}
                                parentname={"targetingForm"}
                                step={0.01}


                            />
                        </div>
                        </OptionalField>

                        <OptionalField targetName={"merchSpendRangeOption"} targetValue={"Greater Than Price"} parentname={this.props.parentname}>

                            <div className="col-lg-3 mg-t-10 mg-lg-t-0">
                                <Input
                                    type={"number"}
                                    labelText={"Minimum Price"}
                                    name={"merchSpendMin"}
                                    placeholder={"$"}
                                    className={"form-control"}
                                    number={"true"}
                                    parentname={"targetingForm"}
                                    step={0.01}

                                />
                            </div>
                        </OptionalField>

                        <OptionalField targetName={"merchSpendRangeOption"} targetValue={"Less Than Price"} parentname={this.props.parentname}>

                            <div className="col-lg-3 mg-t-10 mg-lg-t-0">
                                <Input
                                    type={"number"}
                                    labelText={"Maximum Price"}
                                    name={"merchSpendMax"}
                                    placeholder={"$"}
                                    className={"form-control"}
                                    number={"true"}
                                    parentname={"targetingForm"}
                                    step={0.01}


                                />
                            </div>
                        </OptionalField>
                        <OptionalField targetName={"merchantSpendDateOptions"} targetValue={"Date Range"} parentname={this.props.parentname}>
                            <div className="col-lg-2 mg-t-10 mg-lg-t-0">
                                <DatePicker
                                    labelText={"Start Date"}
                                    name={"merchSpendStartDate"}
                                    className={"form-control"}
                                    parentname={"targetingForm"}


                                />

                            </div>
                            <div className="col-lg-2 mg-t-10 mg-lg-t-0">
                                <DatePicker
                                    labelText={"End Date"}
                                    name={"merchSpendEndDate"}
                                    className={"form-control"}
                                    parentname={"targetingForm"}

                                />

                            </div>
                        </OptionalField>

                        <OptionalField targetName={"merchantSpendDateOptions"} targetValue={"Rolling Lookback"} parentname={this.props.parentname}>
                            <div className="col-lg-3 mg-t-10 mg-lg-t-0">
                            <Input
                                type={"number"}
                                labelText={"Number of Days"}
                                name={"merchantSpendNumberOfDays"}
                                placeholder={"30"}
                                className={"form-control"}
                                number={"true"}
                                parentname={"targetingForm"}


                            />
                            </div>
                        </OptionalField>




                    </div>

                <HorizontalRule/>

                <h5>Sales at {this.props.selectedValue}</h5>
                <div className={"row"}>
                    <div className={"col"}>
                        <DateRangeOptions name={"competitorSpendDateOptions"} parentname={this.props.parentname}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-2 mg-t-10 mg-lg-t-0">
                        <DropDown

                            labelText={"Type"}
                            options={this.state.options}
                            name={"compCatSpendRangeOption"}
                            className={"form-control"}
                            parentname={"targetingForm"}


                        />
                    </div>
                    <OptionalField targetName={"compCatSpendRangeOption"} targetValue={"Between Prices"} parentname={this.props.parentname}>

                    <div className="col-lg-3 mg-t-10 mg-lg-t-0">
                        <Input
                            type={"number"}
                            labelText={"Minimum Price"}
                            name={"compCatSpendMin"}
                            placeholder={"$"}
                            className={"form-control"}
                            parentname={"targetingForm"}
                            step={0.01}

                        />
                    </div>
                    <div className="col-lg-3 mg-t-10 mg-lg-t-0">
                        <Input
                            type={"number"}
                            labelText={"Maximum Price"}
                            name={"compCatSpendMax"}
                            placeholder={"$"}
                            className={"form-control"}
                            parentname={"targetingForm"}
                            step={.01}

                        />
                    </div>

                    </OptionalField>

                    <OptionalField targetName={"compCatSpendRangeOption"} targetValue={"Greater Than Price"} parentname={this.props.parentname}>

                        <div className="col-lg-3 mg-t-10 mg-lg-t-0">
                            <Input
                                type={"number"}
                                labelText={"Minimum Price"}
                                name={"compCatSpendMin"}
                                placeholder={"$"}
                                className={"form-control"}
                                parentname={"targetingForm"}
                                step={0.01}

                            />
                        </div>

                    </OptionalField>

                    <OptionalField targetName={"compCatSpendRangeOption"} targetValue={"Less Than Price"} parentname={this.props.parentname}>

                        <div className="col-lg-3 mg-t-10 mg-lg-t-0">
                            <Input
                                type={"number"}
                                labelText={"Maximum Price"}
                                name={"compCatSpendMax"}
                                placeholder={"$"}
                                className={"form-control"}
                                parentname={"targetingForm"}
                                step={.01}

                            />
                        </div>

                    </OptionalField>
                    <OptionalField targetName={"competitorSpendDateOptions"} targetValue={"Date Range"} parentname={this.props.parentname}>
                        <div className="col-lg-2 mg-t-10 mg-lg-t-0">
                            <DatePicker
                                labelText={"Start Date"}
                                name={"compCatSpendStartDate"}
                                className={"form-control"}
                                parentname={"targetingForm"}


                            />

                        </div>
                        <div className="col-lg-2 mg-t-10 mg-lg-t-0">
                            <DatePicker
                                labelText={"End Date"}
                                name={"compCatSpendEndDate"}
                                className={"form-control"}
                                parentname={"targetingForm"}

                            />

                        </div>
                    </OptionalField>

                    <OptionalField targetName={"competitorSpendDateOptions"} targetValue={"Rolling Lookback"} parentname={this.props.parentname}>
                        <div className="col-lg-3 mg-t-10 mg-lg-t-0">
                            <Input
                                type={"number"}
                                labelText={"Number of Days"}
                                name={"competitorSpendNumberOfDays"}
                                placeholder={"30"}
                                className={"form-control"}
                                number={"true"}
                                parentname={"targetingForm"}


                            />
                        </div>
                    </OptionalField>

                </div>



            </>
        );
    }


}


const mapStateToProps = (state,ownProps) => {

    let form = state.forms.targetingForm;
    let selectedValue = form ? form.targetOption : undefined;

    let merchantName = Cookies.get("merchantName") ?? (state.user && state.user.name ? state.user.name : "Delete 7");

    return {
        selectedValue,
        merchantName
    }

}
export default connect(mapStateToProps)(Spend);