import React, {Component} from "react"
import { connect } from "react-redux"
import {inputChanged} from "../../../Redux/form/action";

class Radio extends Component{

    constructor(props) {
        super(props);


        this.generateRadioButtons = this.generateRadioButtons.bind(this);

        this.onValueChange = this.props.onChange ? this.props.onChange : this.onValueChange.bind(this);
        this.props.inputChanged(this.props.options[0]);

    }

    onValueChange(event) {
        this.props.inputChanged(event.target.value)
    }


    generateRadioButtons(){

        return this.props.options.map((value,index)=>{

            return(
                    <div className={this.props.radioStyle} key={index}>
                    <label >
                        <input
                            type="radio"
                            value={value}
                            checked={this.props.selectedOption === value}
                            onChange={this.onValueChange}
                        />
                        &nbsp;&nbsp;{value}
                    </label>
                    </div>

            );

        });
    }



    render(){

        if(this.props.visible === undefined || this.props.visible === true){

            return(
                <div >
                    {this.generateRadioButtons()}
                </div>
            );


        }
        else
            return <></>


    }






}

const mapStateToProps = (state,ownProps) => {

    let form = state.forms[ownProps.parentname];
    let field = form ? form[ownProps.name] : undefined;
    let value = field ? field : ownProps.options[0];
    return {
        selectedOption: value
    }
}

const mapDispatchToProps = (dispatch,ownProps) => {
    return {
        inputChanged: (value) => dispatch(inputChanged(ownProps.parentname,ownProps.name,value)),


    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Radio)
