import React, {Component} from "react";
import Cookies from "js-cookie";

class ViewCampaigns extends Component{

    constructor(props) {
        super(props);
    }

    renderBoxes(){
        let arr = Cookies.get("campaigns");
        if(arr){
            arr = JSON.parse(arr);
        }else{
            arr = []
        }
        console.log(arr);
        return arr?.map(({id}) =>{

            return(
                <>
                    <hr/>
                    <div className={"row"}>
                        <div className={"col-lg-8"}>

                            <h5>{`Id: ${id}`}</h5>

                        </div>
                        <div className={"col-lg-2"}>

                            <button type="button" className="btn btn-primary" onClick={() => window.location.href = `/campaigns/view/${id}`}>{`Visualize`}</button>

                        </div>
                    </div>

                </>
            );

        })


    }

    render(){

        return(
            <>
                <h3>Campaigns</h3>
                {this.renderBoxes()}

            </>
        )

    }



}
export default ViewCampaigns;