import React, {Component} from "react"
import { connect } from "react-redux"

let rerender = 0;
class OptionalField extends Component {

    constructor(props) {
        super(props);




    }

    render() {

        let show = false;

        if(Array.isArray(this.props.selectedValue)){

            for(let index in this.props.selectedValue){

                const item = this.props.selectedValue[index];


                if(item.name === this.props.targetValue){
                    show = item.checked;
                    break;
                }

            }

        }else{
            show = this.props.selectedValue === this.props.targetValue
        }
        return (show)
        ?
            <>
                {this.props.children}
            </>
            :
            <></>


    }
}
const mapStateToProps = (state,ownProps) => {

    let form = state.forms[ownProps.parentname];
    let value = form ? form[ownProps.targetName] : undefined;
    rerender++;
    return {
        selectedValue: value,
        rerender:rerender
    }

}
export default connect(mapStateToProps)(OptionalField)
