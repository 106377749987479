import React, { Component } from "react";

export default class Select extends Component {
  render() 
  {
    const {name, className, onChange, labelText, placeholder, options, selectedValue} = this.props;
    return (
      <>
          <label>{labelText}</label>
          <select
            name={name}
            className={className}
            onChange={onChange}
            placeholder={placeholder}
            value={selectedValue}
          >  
          {options.map((option, key) => {
            
            return (
              <option key={key} value={key}>{option}</option>
            );
          })}
        </select>
      </>
    );
  }
}
