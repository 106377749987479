import React, { Component } from "react";
import {errorMessages, inputChanged} from "../../../Redux/form/action";
import { connect } from "react-redux"


class DropDown extends Component {

    constructor(props) {
        super(props);

        this.onValueChange = this.onValueChange.bind(this);
        this.props.inputChanged(this.props.options[0]);
        this.getValue = this.getValue.bind(this);

    }

    onValueChange(event) {
        this.props.inputChanged(this.props.options[event.target.value]);
    }

    getValue(){

        for(let index in this.props.options){

            if(this.props.options[index] === this.props.value ){
                return index;
            }


        }
        return 0;

    }

    render()
    {
        const {labelText, options,className} = this.props;
        return (
            <div style={styles}>
                <label>{labelText}</label>
                <select
                    onChange={this.onValueChange}
                    value={this.getValue()}
                    className={className}

                >
                    {options.map((option, key) => {

                        return (
                            <option key={key} value={key}>{option}</option>
                        );
                    })}
                </select>
            </div>
        );
    }
};

const styles = {

    paddingBottom:"20px"


}

const mapStateToProps = (state,ownProps) => {

    let form = state.forms[ownProps.parentname];
    let field = form ? form[ownProps.name] : undefined;
    let value = field ? field : '';

    return {
        value

    }
}

const mapDispatchToProps = (dispatch,ownProps) => {
    return {
        inputChanged: (value) => dispatch(inputChanged(ownProps.parentname,ownProps.name,value)),


    }
}


export default connect(mapStateToProps, mapDispatchToProps)(DropDown)
