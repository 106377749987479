import React, { Component } from "react";
import {errorMessages, inputChanged} from "../../../Redux/form/action";
import { connect } from "react-redux"


class Input extends Component {

    constructor(props) {
        super(props);

        this.onValueChange = this.onValueChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.step = this.props.step;
    }

    onValueChange(event) {
        let {value} = event.target;

        this.props.inputChanged(value)

    }

    onBlur(){
        if(this.step){
            const value = parseFloat(this.props.value).toFixed(2);
            this.props.inputChanged(value);
        }

    }

    render()
    {
        const {name,type, className, labelText, placeholder, errorMsg, step, required, newValue, readOnly,symbol,onChange,onBlur,suggestions,suggestHandler,errorMessage} = this.props;
        return (
            <div className={"form-group"}>
                <label className={"control-label"} htmlFor={name}>{labelText}</label>
                <div className={"input-group"}>
                    <div className={"input-group-addon"}>
                        {symbol}
                    </div>

                <input
                    id={name}
                    name={name}
                    type={type}
                    readOnly={readOnly || false}
                    placeholder={placeholder}
                    className={className}
                    onChange={onChange?onChange:this.onValueChange}
                    required={required}
                    onBlur={onBlur || this.onBlur}
                    value={onChange?newValue?newValue:'':this.props.value || ''}
                    step = {step || "any"}

                />
                    {suggestions && suggestions.map((suggestion) =>
                        <div className="suggestion col-md-12 justify-content-md-center" onClick={() => suggestHandler(suggestion)}>{suggestion}</div>
                    )}

            </div>
                <span className="error" style={{ color: "red",width:"100%",fontSize:"14px" }}>
            {errorMessage}
          </span>
            </div>
        );
    }
}


const mapStateToProps = (state,ownProps) => {

    let form = state.forms[ownProps.parentname];
    let field = form ? form[ownProps.name] : undefined;
    let value = field ? field : '';

    let errors = form ? form.errorMessages : undefined;
    let errorField = errors ? errors[ownProps.name] : undefined;
    let errorMessage = errorField ? errorField : '';

    return {
        value,
        errorMessage
    }
}

const mapDispatchToProps = (dispatch,ownProps) => {
    return {
        inputChanged: (value) => dispatch(inputChanged(ownProps.parentname,ownProps.name,value)),


    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Input)
