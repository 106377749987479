import React,{Component} from "react"
import CheckBox from "../../../../Form/Controls/CheckBox";
import OptionalField from "../../../../Form/Controls/OptionalField";
import Input from "../../../../Form/Controls/Input";

class Search extends Component{

        constructor(props) {
            super(props);

            this.state = {

                checkBoxes:["Website Visits","Phone Calls","Store Visits","App Downloads"]

            }
        }



    render(){
        return(
            <>
                <h5>Select the ways you'd like to reach your goal</h5>
               <CheckBox options={this.state.checkBoxes} name={"searchOptions"}/>
               <OptionalField targetName={"searchOptions"} targetValue={"Website Visits"}>
                  <Input
                      type={"text"}
                      labelText={"Web Address"}
                      name={"webAddress"}
                      placeholder={"http://www.test.com"}
                      className={"form-control"}
                      number={"false"}


                  />

               </OptionalField>
                <OptionalField targetName={"searchOptions"} targetValue={"Phone Calls"}>
                    <Input
                        type={"text"}
                        labelText={"Phone Number"}
                        name={"phoneNumber"}
                        placeholder={"9051234567"}
                        className={"form-control"}
                        number={"true"}


                    />

                </OptionalField>

                <OptionalField targetName={"searchOptions"} targetValue={"App Downloads"}>
                    <Input
                        type={"text"}
                        labelText={"App Name"}
                        name={"appName"}
                        placeholder={"App"}
                        className={"form-control"}
                        number={"false"}


                    />

                </OptionalField>
            </>
        )
    }



}

export default Search