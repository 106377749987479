import React,{Component} from "react"
import Input from "./Form/Controls/Input";
import {getMerchantList} from "../api/api";
import Cookies from 'js-cookie';
import AddMerchant from "./Merchant/AddMerchant";


class ChangeMerchant extends Component{

    constructor(props){
        super(props);

        this.state ={
            typeOptions:["Competitor", "Category"],
            arrays:[],
            merchants:[],
            refresh:true,

            

        };
        for(let index in this.state.typeOptions) {
            let arr = [];

            arr.push({name:'',suggestions:[]})


            this.state.arrays.push(arr);

        }
        
        this.handleOnChange = this.handleOnChange.bind(this);
        this.clearSuggestions = this.clearSuggestions.bind(this);
        this.onSuggestHandler = this.onSuggestHandler.bind(this);

        this.updateMerchantList();

    }

    async updateMerchantList(){
        const merchants = await getMerchantList();
        console.log(merchants)
        this.setState({merchants})


    }

    clearSuggestions(index){

        setTimeout(()=>{

            const {arrays} = this.state;
            arrays[0][index].suggestions = [];
            this.setState({arrays,refresh: !this.state.refresh})


        },500)


    }


    onSuggestHandler = (index,text ) => {
        const {arrays} = this.state;
        arrays[0][index].name = text;
        arrays[0][index].suggestions = [];
        this.setState({refresh: !this.state.refresh,arrays})

    }
    
    handleOnChange(event,index){
        let suggestions = [];
        const text = event.target.value;

        if(text.length >0){

            suggestions = this.state.merchants.filter(merchant =>{

                const regex = new RegExp(`${text}`,"gi");
                return merchant.name.match(regex);

            })
        }


        const {arrays} = this.state;
        arrays[0][index].name = event.target.value;
        arrays[0][index].suggestions = suggestions;

        this.setState({arrays, refresh: !this.state.refresh});

        
        
    }

    genBoxes(){
        for(let index in this.state.typeOptions){

            if(this.state.typeOptions[index] === this.state.typeOptions[0]){

                let type = this.state.typeOptions[index];
                return this.state.arrays[index].map((item,key)=>{
                    return(<div className={"col-lg-4 mg-t-10 mg-lg-t-0"} key={key}>

                        <Input
                            type={"text"}
                            labelText={`Merchant`}
                            placeholder={`${type} Name`}
                            className={"form-control"}
                            number={"false"}
                            parentname={"targetingForm"}
                            name={key}
                            newValue={item.name}
                            onChange={(event) => this.handleOnChange(event,key)}
                            onBlur={()=>this.clearSuggestions(key)}
                            suggestions = {item.suggestions.map((value)=>value.name)}
                            suggestHandler = {this.onSuggestHandler.bind(this,key)}



                        />

                    </div>);

                });

            }

        }

    }

    changeMerchant(){

        let merchantId  = 885
        let  merchantName = "Fortinos"
        
        for(let index in this.state.arrays[0]){
            const {name} = this.state.arrays[0][index];

            for(let index2 in this.state.merchants){

                const merchant = this.state.merchants[index2];


                if(name == merchant.name){
                  merchantId = merchant.id;
                  merchantName = merchant.name;
                }




            }


        }
    
    
    Cookies.set("merchant",merchantId);
    Cookies.set("merchantName", merchantName);


    }


    render(){

        return(
            <>
            <div className="row">
                <h1>Change Merchant</h1>
            </div>
                <div className="row">
                        {this.genBoxes()}
                        <div className={"col-2"}>
                        
                        </div>
                </div>
                <div className="row">
                            <button type="button" className="btn btn-primary"
                                onClick={() => this.changeMerchant()}>
                                    Change Merchant
                            </button>
                </div>
              
                <br />
                <br />
                <br />
                <br />
                <br />

                <AddMerchant/>
            </>
        )
    }




}

export default ChangeMerchant