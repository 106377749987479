import React, { Component } from "react";
import BrandAwareness from "./FacebookSub/BrandAwareness";
import Engagement from "./FacebookSub/Engagement";
import VideoViews from "./FacebookSub/VideoViews";
import LeadGeneration from "./FacebookSub/LeadGeneration";
import Messages from "./FacebookSub/Messages";
import Input from "../../Form/Controls/Input";
import DynamicCreative from "./DynamicCreative";

class FacebookQuestions extends Component {
    constructor(props) {
        super(props);

        this.state = {

            categories:[
                {
                    title:"Awareness",
                    list:["Brand Awareness","Reach"]
                },
                {
                    title:"Consideration",
                    list:["Traffic","Engagement","App Installs","Videos Views","Lead Generation","Messages"]
                },
                {
                    title:"Conversions",
                    list:["Conversions","Store Traffic"]
                }

            ],
            selectedOption:""


        }


        this.generateRadioButtons = this.generateRadioButtons.bind(this);
        this.onValueChange = this.onValueChange.bind(this);

    }

    onValueChange(event) {
        this.setState({
            selectedOption: event.target.value
        });
    }

    generateRadioButtons(){

        return this.state.categories.map((value,index)=>{
           return(
               <div className="col-lg mg-t-4 mg-lg-t-0" key={index}>
                   <h3>
                       {value.title}
                   </h3>
                   <br/>
                   {value.list.map((item,i)=>{

                       return(
                           <div className="radio">
                               <label>
                               <input
                               type="radio"
                               value={item}
                               checked={this.state.selectedOption === item}
                               onChange={this.onValueChange}
                               />
                                   &nbsp;&nbsp;{item}
                               </label>
                           </div>
                       )

                   })}

            </div>
           );

        });


    }


    render(){

        let content =<></>;
        switch (this.state.selectedOption){

            case "Brand Awareness":
                content =<BrandAwareness/>;
                break;
            case  "Engagement":
                content = <Engagement/>
                break;

            case "Videos Views":
                content = <VideoViews/>
                break;

            case "Lead Generation":
                content = <LeadGeneration/>
                break;

            case "Messages":
                content = <Messages/>
                break;


        }

        return(
            <>
                <div className={"row"}>
                    <div className="col-lg mg-t-4 mg-lg-t-0">
                        <DynamicCreative/>
                    </div>
                </div>
            <div className="row">
                {this.generateRadioButtons()}
            </div>
                <hr/>
            {content}
                <hr/>
                <h5>Creative Mockup</h5>
                <div className={"row"}>
                    <div className="col-lg-12 mg-t-10 mg-lg-t-0">
                        <Input
                            type={"text"}
                            labelText={"Let us know what creative mockup to use."}
                            name={"mockup"}
                            placeholder={""}
                            className={"form-control"}
                            number={"false"}


                        />
                    </div>
                </div>
            </>

        )


    }


}

export default FacebookQuestions;
