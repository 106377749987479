import React, { Component } from "react";
import Select from "../../../forms/Select";

class Devices extends Component {
    constructor(props) {
        super(props);

        this.state = {
            optionsList: [
                {
                    name:"iOS",
                    checked:false
                },
                {
                    name:"Android",
                    checked:false
                },
                {
                    name:"Windows",
                    checked:false
                },
                {
                    name:"Linux",
                    checked:false
                },
                {
                    name:"macOS",
                    checked:false
                }
            ],
            skippableAds:false,

        }

        this.generateOptions = this.generateOptions.bind(this);
        this.inputChangeHandler = this.inputChangeHandler.bind(this);

    }

    handleCheck(name){

        let optionsList = this.state.optionsList.map((value)=>{

            if(value.name === name){
                return {name,checked:!value.checked};
            }
            else
                return value;

        });

        this.setState({optionsList});




    }

    generateOptions(){

        return this.state.optionsList.map((value,index)=>{

            return  (
                <div className="col-lg-12 mg-t-10 mg-b-20 mg-lg-t-0">

                    <label key={index}>
                        <input
                            type="checkbox"
                            checked={value.checked}
                            onChange={()=>this.handleCheck(value.name)} />
                        &nbsp;&nbsp;{value.name}

                    </label>
                </div>
            );


        });

    }

    inputChangeHandler(event) {
        this.setState({ [event.target.name]: event.target.value });

    }

    render(){



        return(

            <>
                <div className="row">
                    <h5>
                        Specific Devices and Operating Systems
                    </h5>
                </div>
                <br/>
                <div className="row">
                    {this.generateOptions()}
                </div>
                <div className={"row"}>
                    <div className="col-lg-3 mg-t-10 mg-b-20 mg-lg-t-0">
                    <h5>Skippable Ads</h5>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-2 mg-t-10 mg-b-20 mg-lg-t-0">
                        <label>
                            <input
                                name="skippableAds"
                                type="checkbox"
                                checked={this.state.skippableAds}
                                onChange={this.handleCheck} />
                            &nbsp;&nbsp; Allow Skippable Ads?
                        </label>
                    </div>
                </div>
            </>

        )


    }


}

export default Devices;
