import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import BudgetAndSchedule from "./Components/BudgetAndSchedule";
import Placements from "./Components/Placements";
import Devices from "./Components/Devices";
import Optimization from "./Components/Optimization";

class LeadGeneration extends Component {
    constructor(props) {
        super(props);
        this.state= {
            radioOptions:["Instant Forms","Automated Chat","Calls"],
            selectedOption:0

        }

        this.setState({selectedOption: this.state.radioOptions[0]});

        this.generateRadioButtons = this.generateRadioButtons.bind(this);


    }


    generateRadioButtons(){

        return this.state.radioOptions.map((value,index)=>{

            return(
                <div className="radio col-lg-2 mg-t-10 mg-b-20 mg-lg-t-0" key={index}>
                    <label>
                        <input
                            type="radio"
                            value={value}
                            checked={this.state.selectedOption === value}
                            onChange={(e)=>this.onValueChange("selectedOption",e)}
                        />
                        &nbsp;&nbsp;{value}
                    </label>
                </div>
            );

        });
    }



    render(){



        return(

            <>
                <div className={"row"}>
                    <h5>
                        Lead Method
                    </h5>
                </div>
                <br/>
                <div className={"row"}>
                    {this.generateRadioButtons()}
                </div>
                <hr/>

                <BudgetAndSchedule/>
                <hr/>
                <Placements/>


            </>

        )


    }


}

export default LeadGeneration;
