import React, {Component} from 'react'
import { connect } from "react-redux"
import {inputChanged} from "../../../Redux/form/action";
import ReactDatePicker, {getYear,getMonth} from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";

class DatePicker extends Component{

    constructor(props) {
        super(props);

        let years = [];

        for(let i = -2; i<=0; i++){

            years.push(new Date().getFullYear()+i)

        }
        this.state = {
            months: [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December"
            ],
            years
        }



        this.onValueChange = this.onValueChange.bind(this);
    }

    onValueChange(date) {
        this.props.inputChanged(date)
    }


    render(){

        const {name,type, className, labelText, placeholder, errorMsg, number, required, newValue, readOnly,symbol,onChange,startDate} = this.props;

        return(


            <div className={"form-group"}>
                <label className={"control-label"} htmlFor={name}>{labelText}</label>
                <div className={"input-group"}>
                    <div className={"input-group-addon"}>
                        {symbol}
                    </div>

                    <ReactDatePicker
                        renderCustomHeader={({
                                                 date,
                                                 changeYear,
                                                 changeMonth,
                                                 decreaseMonth,
                                                 increaseMonth,
                                                 prevMonthButtonDisabled,
                                                 nextMonthButtonDisabled
                                             }) => (
                            <div
                                style={{
                                    margin: 10,
                                    display: "flex",
                                    justifyContent: "center"
                                }}
                            >
                                <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                    {"<"}
                                </button>
                                <select
                                    value={date.getFullYear()}
                                    onChange={({ target: { value } }) => changeYear(value)}
                                >
                                    {this.state.years.map(option => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>

                                <select
                                    value={this.state.months[(date.getMonth())]}
                                    onChange={({ target: { value } }) =>
                                        changeMonth(this.state.months.indexOf(value))
                                    }
                                >
                                    {this.state.months.map(option => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>

                                <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                    {">"}
                                </button>
                            </div>
                        )}
                        onChange={onChange?onChange:this.onValueChange}
                        selected={onChange?startDate|| new Date() : this.props.selectedOption}
                        className={this.props.className}

                    />

                </div>
                <span className="error" style={{ color: "red",width:"100%",fontSize:"14px" }}>
            {this.props.errorMessage}
          </span>
            </div>

        )

    }


}
const mapStateToProps = (state,ownProps) => {
    let form = state.forms[ownProps.parentname];
    let field = form ? form[ownProps.name] : undefined;
    let value = field ? field : new Date;
    return {
        selectedOption: value
    }


}

const mapDispatchToProps = (dispatch,ownProps) => {
    return {
        inputChanged: (value) => dispatch(inputChanged(ownProps.parentname,ownProps.name,value)),


    }
}


export default connect(mapStateToProps, mapDispatchToProps)(DatePicker)