import React,{Component} from "react";
import Radio from "../../Form/Controls/Radio"
import {inputChanged} from "../../../Redux/form/action";
import {connect} from "react-redux";
import OptionalField from "../../Form/Controls/OptionalField";
import Input from "../../Form/Controls/Input";
import CheckBox from "../../Form/Controls/CheckBox";

class UserSelection extends Component{

    constructor(props) {
        super(props);
        this.state = {options:["All Users","Target Users"]}
        this.onChange = this.onChange.bind(this);
       

    }


    onChange(value){
        this.props.inputChanged("userSelection",value.target.value)
    }

    render(){

        return(
            <>
                <div className={"row"}>
                    <div className={"col"}>
                        <h5>User Selection</h5>
                    </div>
                </div>
                <div className="row">
                    <div className={"col-lg"}>
                        <Radio
                            options = {this.state.options}
                            name = {"userSelection"}
                            parentname={this.props.parentname}
                            onChange ={this.onChange}
                        />
                    </div>
                </div>
            </>


        )


    }




}

const mapStateToProps = (state,ownProps) => {

 return{
        ...state
 }

}
const mapDispatchToProps = (dispatch,ownProps) => {
    return {
        inputChanged: (field,value) => dispatch(inputChanged("targetingForm",field,value)),


    }
}
export default connect(mapStateToProps,mapDispatchToProps)(UserSelection)
