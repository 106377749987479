import React, { Component } from "react";
import {errorMessages, inputChanged} from "../../../Redux/form/action";
import { connect } from "react-redux"


class TextArea extends Component {

    constructor(props) {
        super(props);

        this.onValueChange = this.onValueChange.bind(this);
    }

    onValueChange(event) {
        this.props.inputChanged(event.target.value)
    }

    render()
    {
        const {name,type, className, labelText, placeholder, rows,errorMsg, required, newValue, readOnly,symbol,onChange,onBlur,suggestions,suggestHandler} = this.props;
        return (
            <div className={"form-group"}>
                <label className={"control-label"} htmlFor={name}>{labelText}</label>
                <div className={"input-group"}>
                    <div className={"input-group-addon"}>
                        {symbol}
                    </div>

                    <textarea
                        id={name}
                        name={name}
                        type={type}
                        readOnly={readOnly || false}
                        placeholder={placeholder}
                        className={className}
                        onChange={onChange?onChange:this.onValueChange}
                        required={required}
                        onBlur={onBlur}
                        value={onChange?newValue?newValue:'':this.props.value || ''}
                        rows={rows}

                    />
                    {suggestions && suggestions.map((suggestion) =>
                        <div className="suggestion col-md-12 justify-content-md-center" onClick={() => suggestHandler(suggestion)}>{suggestion}</div>
                    )}

                </div>
                <span className="error" style={{ color: "red",width:"100%",fontSize:"14px" }}>
            {this.props.errorMessage}
          </span>
            </div>
        );
    }
};


const mapStateToProps = (state,ownProps) => {

    let form = state.forms[ownProps.parentname];
    let field = form ? form[ownProps.name] : undefined;
    let value = field ? field : '';

    let errors = form ? form.errorMessages : undefined;
    let errorField = errors ? errors[ownProps.name] : undefined;
    let errorMessage = errorField ? errorField : '';

    return {
        value,
        errorMessage
    }
}

const mapDispatchToProps = (dispatch,ownProps) => {
    return {
        inputChanged: (value) => dispatch(inputChanged(ownProps.parentname,ownProps.name,value)),


    }
}


export default connect(mapStateToProps, mapDispatchToProps)(TextArea)
