import React, {Component} from 'react'
import Input from "../../../Form/Controls/Input";
import CheckBox from "../../../Form/Controls/CheckBox";
import DatePicker from "../../../Form/Controls/DatePicker";
import Radio from "../../../Form/Controls/Radio";
import DropDown from "../../../Form/Controls/DropDown";
import OptionalField from "../../../Form/Controls/OptionalField";

class SecondPage extends Component{

    constructor(props) {
        super(props);

        this.state = {

            additionalParameters:[]

        }
        this.onChange = this.onChange.bind(this);
        this.addBox = this.addBox.bind(this);
        this.addBox();

    }


    onChange(event,id,type){
        let {additionalParameters} = this.state;
        if(type === "Name")
            additionalParameters[id] = {...additionalParameters[id],name:event.target.value};
        else
            additionalParameters[id] = {...additionalParameters[id],value:event.target.value};

        this.setState({additionalParameters});



    }

    addBox(){
        let {additionalParameters} = this.state;
        additionalParameters.push({name:"",value:""});
        this.setState({additionalParameters});



    }

    genBoxes(){

                return this.state.additionalParameters.map((item,key)=>{
                    return(
                        <div className={"row"}key={key}>
                        <div className={"col-lg-4 mg-t-10 mg-lg-t-0"} >

                        <Input
                            type={"text"}
                            labelText={`Name`}
                            placeholder={`Name`}
                            className={"form-control"}
                            number={"false"}
                            newValue={item.name}
                            onChange={(event)=>this.onChange(event,key,"Name")}

                        />

                    </div>
                            <div className={"col-lg-4 mg-t-10 mg-lg-t-0"} key={key}>

                                <Input
                                    type={"text"}
                                    labelText={`Value`}
                                    placeholder={`Value`}
                                    className={"form-control"}
                                    number={"false"}
                                    newValue={item.value}
                                    onChange={(event)=>this.onChange(event,key,"Value")}

                                />

                            </div>
                        </div>

                            );

                });


    }


    render(){


        return(
            <>
                <div className="row">
                    <h5>Campaign</h5>
                </div>
                <div className="row">
                    <div className={"col-lg"}>
                <Input
                    type={"text"}
                    labelText={"Campaign Name"}
                    name={"campaignName"}
                    placeholder={"campaign"}
                    className={"form-control"}
                    number={"false"}


                />
                    </div>
                </div>
                <hr/>
                <div className="row">
                    <h5>Networks</h5>
                </div>
                <div className="row">
                <div className={"col-lg"}>
                        <CheckBox
                           options = {["Search","Display"]}
                           name = {"networks"}
                           defaultValues = {[{name:"Search", checked:true},{name:"Display", checked:true}]}


                        />
                    </div>
                </div>

                <hr/>
                <div className="row">
                    <h5>Dates</h5>
                </div>
                <div className="row">
                <div className="col-lg-2 mg-t-10 mg-lg-t-0">
                    <DatePicker
                        labelText={"Start Date"}
                        name={"startDate"}
                        className={"form-control"}


                    />

                </div>
                <div className="col-lg-2 mg-t-10 mg-lg-t-0">
                    <DatePicker
                        labelText={"End Date"}
                        name={"endDate"}
                        className={"form-control"}

                    />

                </div>
                </div>
                <hr/>
                <div className="row">
                    <h5>Campaign Url Options</h5>
                </div>
                <div className="row">
                    <div className={"col-lg"}>
                        <Input
                            type={"text"}
                            labelText={"Tracking Template"}
                            name={"trackingTemplate"}
                            placeholder={"?"}
                            className={"form-control"}
                            number={"false"}


                        />
                    </div>
                        <div className={"col-lg"}>
                            <Input
                                type={"text"}
                                labelText={"Final URL Suffix"}
                                name={"finalUrlSuffix"}
                                placeholder={"?"}
                                className={"form-control"}
                                number={"false"}


                            />
                    </div>
                    </div>
                <div className="row">
                    <h5>Custom Parameters</h5>
                </div>
                <div className="row">
                    {this.genBoxes()}
                </div>
                <div className="row">
                    <div className={"col-lg mg-t-10 mg-lg-t-0"} style={{paddingBottom:"20px"}}>
                        <button type="button" className="btn btn-primary" onClick={this.addBox}>{`Add Extra Parameter`}</button>
                    </div>

                </div>
                <hr/>
                <div className="row">
                    <h5>Dynamic Search Settings</h5>
                </div>

                <div className="row">
                    <div className={"col-lg"}>
                        <Input
                            type={"text"}
                            labelText={"Domain"}
                            name={"domain"}
                            placeholder={"https://www.test.com"}
                            className={"form-control"}
                            number={"false"}


                        />
                    </div>
                </div>

                <div className="row">
                    <div className={"col-lg"}>
                        <Radio
                            options = {["Use Google's index of my website","Use URLs from my page feed only", "Use Both"]}
                            name = {"domainOptions"}


                        />
                    </div>
                </div>
                <hr/>
                <div className="row">
                    <h5>Budget</h5>
                </div>

                <div className="row">
                    <div className="col-lg-4 mg-t-10 mg-lg-t-0">
                        <DropDown

                            labelText={"What would you like to focus on?"}
                            options={["Conversions","Conversion Value","Clicks","Impression Share"]}
                            name={"targetOption"}
                            className={"form-control"}
                            parentname={"targetingForm"}


                        />
                    </div>
                </div>

                <div className="row">
                    <div className={"col-lg"}>
                        <Input
                            type={"text"}
                            labelText={"Target CPA (Optional)"}
                            name={"targetCPA"}
                            placeholder={"$"}
                            className={"form-control"}
                            number={"True"}


                        />
                    </div>
                </div>
                <hr/>
                <div className="row">
                    <h5>Site Extensions</h5>
                </div>
                <div className="row">
                    <div className={"col-lg"}>
                        <CheckBox
                            options = {["Sitelink Extensions","Call Out Extensions","Structured Snippet Extensions","App Extensions","Promotion Extensions","Price Extensions"]}
                            name = {"siteExtensions"}


                        />
                    </div>
                </div>

                <div className="row">
                    <div className={"col-lg"}>
                        <OptionalField targetName={"siteExtensions"} targetValue={"Website Visits"}>
                            <Input
                                type={"text"}
                                labelText={"Web Address"}
                                name={"webAddress"}
                                placeholder={"http://www.test.com"}
                                className={"form-control"}
                                number={"false"}


                            />
                        </OptionalField>

                    </div>
                </div>


            </>


        )

    }



}
export default SecondPage
