import React, { Component } from "react";
import { connect } from "react-redux"
import logo from "../../Images/Logos/fortions.png"
import Cookies from "js-cookie";

class Header extends Component {

  constructor(props) {
    super(props);

  }



  render() {
    return (
      <div style={styles}>
        <div className={"row"}>
          <div className={"col-4"}>
        <h4 style={logoStyle}><span style={bracketStyle}>[</span>Swerve<span style={bracketStyle}>]</span></h4>
          </div>

          <div className={"col-8"}>
            <div className={"row"}>
              {true?<h6 style={merchantStyle}>{this.props.merchantName}</h6>:<img src={logo} style={{height:"60px",position:"absolute",right:"20px",top:"0px"}}/>}
            
            </div>
          </div>
        </div>

      </div>

    );
  }
}

const circleStyle={
  position:"absolute",
  right:"20px",
  paddingLeft:"7px",
  paddingTop:"10px",
  marginLeft:"10px",
  marginTop:"5px",
  height:"50px",
  width:"50px",
  backgroundColor: "#E9ECEF",
  borderRadius: "50%",
  display: "inline-block"

}

const merchantStyle = {

  position:"absolute",
  right:"100px",
  paddingTop:"20px"


}

const bracketStyle = {

  color:"#00b297",
  transitionProperty:"all",
  transitionDuration:"0.2s",
  transitionTimingFunction:"ease-in-out",
  transitionDelay:"0s"



}

const logoStyle = {

  paddingLeft:"15px",
  paddingTop:"15px"

}

const styles = {

  position:"fixed",
  height:"60px",
  width:"100%",
  backgroundColor:"white",
  zIndex:"10"

}


const mapStateToProps = (state,ownProps) => {

  let form = state.forms[ownProps.parentname];
  let selectedValue = form ? form.targetOption : undefined;
  let merchantName = Cookies.get("merchantName") ?? (state.user && state.user.name ? state.user.name : "Delete 7");

  return {
    selectedValue,
    merchantName
  }

}

export default connect(mapStateToProps)(Header)


