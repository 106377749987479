import React,{Component} from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import SignIn from "../SignIn";
import Dashboard from "../Dashboard";
import NewCampaign from "../Campaign/NewCampaign";
import APIKeys from "../APIKeys";
import ViewCampaigns from "../Campaign/ViewCampaigns";
import ChangeMerchant from "../ChangeMerchant";

class Router extends Component{

    constructor(props) {
        super(props);
    }

    render(){

        return(
            <div style={styles}>
        <Switch>

            <Route path={"/signIn"}>
                <SignIn/>
            </Route>
                <Route path={"/keys"}>
                    <APIKeys/>
                </Route>
            <Route path ={"/campaigns/new"}>
                <NewCampaign/>
            </Route>
            <Route path ={"/campaigns/view"}>
                <ViewCampaigns/>
            </Route>
            <Route path ={"/invoices"}>
                <ChangeMerchant/>
            </Route>
            <Route path ={"/dashboard"}>
                <Dashboard/>
            </Route>
            <Route path ={"/"}>
                <Dashboard/>
            </Route>



        </Switch>
            </div>

        );


    }


}

const styles ={

    padding:"50px",

}

export default Router;