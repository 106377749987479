import React, { Component } from "react";

class Placements extends Component {
    constructor(props) {
        super(props);

        this.state = {
              optionsList:  [
        {
            name:"Facebook News Feed",
                checked:false
        },
        {
            name:"Instagram Feeds",
                checked:false
        },
        {
            name:"Facebook Marketplace",
                checked:false
        },
        {
            name:"Facebook Video Feed",
                checked:false
        },
        {
            name:"Facebook Right Column",
                checked:false
        },
        {
            name:"Instagram Explore",
                checked:false
        },
        {
            name:"Messenger Inbox",
                checked:false
        },
        {
            name:"Facebook Group Feeds",
                checked:false
        },
        {
            name:"Facebook Stories",
                checked:false
        },
        {
            name:"Instagram Stories \n",
                checked:false
        },
        {
            name:"Messenger Stories \n",
                checked:false
        },
        {
            name:"Facebook In Stream\n",
                checked:false
        },
        {
            name:"IGTV",
                checked:false
        },
        {
            name:"Facebook Search Results",
                checked:false
        },
        {
            name:"Messenger Supported Messages\n",
                checked:false
        },
        {
            name:"Facebook Instant Articles \n",
                checked:false
        },
        {
            name:"Audience Network Native Banner and Interstitial",
                checked:false
        },
        {
            name:"Audience Network Rewards Videos \n",
                checked:false
        },
                  {
                      name:"Audience Network \n",
                      checked:false
                  },
                  {
                      name:"In Stream Video \n",
                      checked:false
                  }
        ],
            selectedOption:"automatic"
        };

        this.generateManualPlacements = this.generateManualPlacements.bind(this);
        this.onValueChange = this.onValueChange.bind(this);
    }

    handleCheck(name){

        let optionsList = this.state.optionsList.map((value)=>{

            if(value.name === name){
                return {name,checked:!value.checked};
            }
            else
                return value;

        });

        this.setState({optionsList});




    }

    onValueChange(event) {
        this.setState({
            selectedOption: event.target.value
        });
    }

    generateManualPlacements(){
            return this.state.optionsList.map((value,index)=>{

                return  (
                    <div className="col-lg-3 mg-t-10 mg-b-20 mg-lg-t-0">

                    <label key={index}>

                        <input
                            type="checkbox"
                            checked={value.checked}
                            onChange={()=>this.handleCheck(value.name)} />
                        &nbsp;&nbsp;{value.name}
                    </label>
                    </div>
                );


            });
    }


    render(){



        return(
            <>
            <div className={"row"}>
                <h5>
                    Placements
                </h5>
            </div>
                <br/>
                <div className={"row"}>

                <div className="radio col-lg-3 mg-t-10 mg-b-20 mg-lg-t-0">
                    <label>
                        <input
                            type="radio"
                            value={"automatic"}
                            checked={this.state.selectedOption === "automatic"}
                            onChange={this.onValueChange}
                        />
                        &nbsp;&nbsp;Automatic placements
                    </label>
                </div>
                    <div className="radio col-lg-3 mg-t-10 mg-b-20 mg-lg-t-0">
                        <label>
                            <input
                                type="radio"
                                value={"manual"}
                                checked={this.state.selectedOption === "manual"}
                                onChange={this.onValueChange}
                            />
                            &nbsp;&nbsp;Manual placements
                        </label>
                    </div>
                </div>
                {
                    this.state.selectedOption === "automatic" ?
                        <>

                        </>
                        :
                        <>
                            <div className={"row"}>
                                <div className="radio col-lg-3 mg-t-10 mg-b-20 mg-lg-t-0">

                                <h3>Options</h3>
                                </div>
                            </div>
                            <div className={"row"}>
                            {this.generateManualPlacements()}
                            </div>
                        </>

                }
            </>


        )


    }


}

export default Placements;
