import React, { Component } from "react";

export default class Input extends Component {
  render() 
  {
    const {name, type, className, onChange, labelText, placeholder, errorMsg, number, required, value, readOnly} = this.props;
    return (
          <>
          <label>{labelText}</label>
          <input
            type={type}
            name={name}
            readOnly={readOnly}
            placeholder={placeholder}
            className={className}
            onChange={onChange}
            number={number}
            required={required}
            value={value || ''}
          />
          <span className="error" style={{ color: "red" }}>
            {errorMsg}
          </span>
        </>
    );
  }
}
