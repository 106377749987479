import React, { Component } from "react";
import Select from "../../forms/Select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import BudgetAndSchedule from "./Components/BudgetAndSchedule";
import Placements from "./Components/Placements";
import Devices from "./Components/Devices";
import Optimization from "./Components/Optimization";

class BrandAwareness extends Component {
    constructor(props) {
        super(props);
        this.state= {
            dynamicCreative:false

        }


        this.handleCheck = this.handleCheck.bind(this);
    }


    handleCheck(){
        this.setState({dynamicCreative:!this.state.dynamicCreative});
    }


    render(){



        return(

            <>
                <div className="row">
                    <h5>
                        Dynamic Creative
                    </h5>
                </div>
                <br/>
                <div className="row">
                    <div className="col-lg-20 mg-t-10 mg-b-20 mg-lg-t-0">
                        <label>

                            <input
                                name="dynamicCreative"
                                type="checkbox"
                                checked={this.state.dynamicCreative}
                                onChange={this.handleCheck} />
                            &nbsp;&nbsp; Dynamic Creative?
                        </label>
                    </div>
                </div>
                <hr/>
               <BudgetAndSchedule/>
               <hr/>
               <Placements/>
               <hr/>
               <Devices/>
               <hr/>
               <Optimization
               dropDownOptions={["Link Clicks"," Landing Page Views","Impressions","Daily Unique Reach"]}
               radioOptions={["CPI","CPC"]}
               />
                </>

        )


    }


}

export default BrandAwareness;
