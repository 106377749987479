import React, { Component } from "react";
import Select from "../../../forms/Select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import Input from "../../../forms/Input";


class BudgetAndSchedule extends Component {
    constructor(props) {
        super(props);
        this.state= {
            startDate:"",
            endDate:"",
            limit:false,
            lowAmount:null,
            highAmount:null

        }

        this.inputChangeHandler = this.inputChangeHandler.bind(this);
        this.handleStartDate = this.handleStartDate.bind(this);
        this.handleEndDate = this.handleEndDate.bind(this);
        this.handleCheck = this.handleCheck.bind(this);
    }

    inputChangeHandler(event) {
        console.log(event);
        this.setState({ [event.target.name]: event.target.value });

    }

    handleStartDate(startDate) {
        this.setState({ startDate });
    }

    handleEndDate(endDate) {
        this.setState({ endDate });
    }
    handleCheck(){
        this.setState({limit:!this.state.limit});
    }

    render(){



        return(

            <>

                <div className="row">
                    <h5>
                        Budget and Schedule
                    </h5>
                </div>
                <br/>
                    <div className="row">
                    <div className="col-lg-2 mg-t-10 mg-b-20 mg-lg-t-0">
                        <label>Start Date</label><br />
                        <DatePicker
                            className={"form-control"}
                            name={"startDate"}
                            selected={this.state.startDate}
                            onChange={this.handleStartDate}
                            dateFormat={"d-MM-yyyy"}
                            value={((this.state.startDate !== "") ? moment(this.state.startDate).format('DD-MM-YYYY') : "")}
                            showTimeSelect
                        />
                    </div>
                    <div className="col-lg-2 mg-t-10 mg-b-20 mg-lg-t-0">
                        <label>End Date</label><br />
                        <DatePicker
                            className={"form-control"}
                            name={"endDate"}
                            selected={this.state.endDate}
                            onChange={this.handleEndDate}
                            dateFormat="d-MM-yyyy"
                            value={((this.state.endDate !== "") ? moment(this.state.endDate).format('DD-MM-YYYY') : "")}
                            showTimeSelect
                        />
                    </div>
                        <div className="col-lg-2 mg-t-10 mg-b-20 mg-lg-t-0">
                            <br/>
                            <label>

                                <input
                                    name="limit"
                                    type="checkbox"
                                    checked={this.state.limit}
                                    onChange={this.handleCheck} />
                                &nbsp;&nbsp; Set Spend Limits?
                            </label>
                        </div>
                        {this.state.limit === true ?
                            <> <div className="col-lg-2 mg-t-10 mg-b-20 mg-lg-t-0">
                                <Input
                                    labelText={"Min Amount"}
                                    className={"form-control"}
                                    name={"lowAmount"}
                                    onChange={this.inputChangeHandler}
                                    type={"text"}
                                    placeholder={"$"}
                                    number={"true"}
                                    value={this.state.lowAmount >=0 ? this.state.lowAmount : null }
                                />
                            </div>
                                <div className="col-lg-2 mg-t-10 mg-b-20 mg-lg-t-0">
                                    <Input
                                        labelText={"Max Amount"}
                                        className={"form-control"}
                                        name={"highAmount"}
                                        onChange={this.inputChangeHandler}
                                        type={"text"}
                                        placeholder={"$"}
                                        number={"true"}
                                        value={this.state.highAmount >=0 ? this.state.lowAmount : null}
                                    />
                                </div></>
                            :
                            <></>

                        }

                </div>


            </>

        )


    }


}

export default BudgetAndSchedule;
