import React, {Component} from 'react'
import { connect } from "react-redux"
import MetabaseGraph from "./MetabaseGraph";
import Cookies from 'js-cookie';


class Graphs extends Component{

    constructor() {
        super();

    }

    formatDate(date) {
        let d = date ? new Date(date) : new Date(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }
    getGraphs(){

        let params = {};

        if(this.props.formData.userSelection === "Target Users")
        {
            const {
                competitorPurchaseNumberOfDays,
                merchantPurchaseNumberOfDays,
                competitorSpendNumberOfDays,
                merchantSpendNumberOfDays,
                merchantSpendDateOptions,
                competitorSpendDateOptions,
                merchantPurchaseDateOptions,
                competitorPurchaseDateOptions,
                campaignID,
                city,
                compCatPurchaseMax,
                compCatPurchaseMin,
                compCatPurchaseRangeOption,
                compCatSpendMax,
                compCatSpendMin,
                compCatSpendRangeOption,
                country,
                gender,
                maximumAge,
                merchPurchaseMax,
                merchPurchaseMin,
                merchPurchaseRangeOption,
                merchSpendMax,
                merchSpendMin,
                merchSpendRangeOption,
                minimumAge,
                province,
                radius,
                targetOption,
                postalCode,
                
            } = this.props.formData
            const {compCatPurchaseEndDate,compCatPurchaseStartDate,compCatSpendEndDate,compCatSpendStartDate,merchPurchaseEndDate,merchPurchaseStartDate,merchSpendEndDate,merchSpendStartDate} = this.props.formData
            const {competitorList} = this.props.formData;
            const competitors = competitorList[0].map(value => value.id);
            console.log(competitorList)
            console.log(competitors)

            const today = new Date();

            const rollbackMS = new Date();
            rollbackMS.setDate(rollbackMS.getDate()- merchantSpendNumberOfDays || 30)

            const rollbackMP = new Date();
            rollbackMP.setDate(rollbackMP.getDate()- merchantPurchaseNumberOfDays || 30)

            const rollbackCP = new Date();
            rollbackCP.setDate(rollbackCP.getDate()- competitorPurchaseNumberOfDays || 30)

            const rollbackCS = new Date();
            rollbackCS.setDate(rollbackCS.getDate()- competitorSpendNumberOfDays || 30)


            const targetMerchantFreqRange = merchantPurchaseDateOptions === "Date Range"
                ?
                this.formatDate(merchSpendStartDate)+"~"+ (this.formatDate(merchSpendEndDate))
                :
                this.formatDate(rollbackMP)+"~"+ (this.formatDate(today));

            const targetMerchantSpendRange = merchantSpendDateOptions === "Date Range"
                ?
                this.formatDate(merchSpendStartDate)+"~"+(this.formatDate(merchSpendEndDate))
                :
                this.formatDate(rollbackMS)+"~"+ (this.formatDate(today));

            const targetCompetitorFreqRange = competitorPurchaseDateOptions === "Date Range"
                ?
                this.formatDate(merchSpendStartDate)+"~"+(this.formatDate(merchSpendEndDate))
                :
                this.formatDate(rollbackCP)+"~"+ (this.formatDate(today));

            const targetCompetitorSpendRange = competitorSpendDateOptions === "Date Range"
                ?
                this.formatDate(merchSpendStartDate)+"~"+(this.formatDate(merchSpendEndDate))
                :
                this.formatDate(rollbackCS)+"~"+ (this.formatDate(today));


            params = {
                targetMerchantFreqRange,
                targetMerchantSpendRange,
                "targetCompetitors": competitors || [],
                "targetMerchant": this.props.merchantId,
                targetCompetitorFreqRange,
                targetCompetitorSpendRange,
                "minUserAge": minimumAge || 0,
                "maxUserAge": maximumAge || 100,
                "targetMerchantSpendMinimum": merchSpendMin || '0',
                "targetMerchantSpendMaximum": merchSpendMax || '1000000',
                "targetCompetitorFreqMinimum": compCatPurchaseMin || 0,
                "targetCompetitorFreqMaximum": compCatPurchaseMax ||1000000,
                "targetMerchantFreqMinimum": merchPurchaseMin || '0',
                "targetMerchantFreqMaximum": merchPurchaseMax || '1000000',
                "targetCompetitorSpendMinimum": compCatSpendMin || '0',
                "targetCompetitorSpendMaximum": compCatSpendMax || '1000000',
                "userGender":gender === "Male" ? "M" : gender === "Female" ? "F" : gender === "Other" ? "O" : ["M","F"],
                "country" : "Canada",
                "province" : province === "All" ? ["Alberta","British Columbia","Manitoba","New Brunswick","Newfoundland and Labrador","Northwest Territories","Nova Scotia","Nunavut","Ontario","Prince Edward Island","Quebec","Saskatchewan","Yukon"] : province,
                postalCode,
                radius : radius || 0
            
            };
        }
        let questions = [{question:101,size:12},{question:100,size:12},{question:98,size:12},{question:102,size:6},{question:103,size:6},{question:104,size:12}];


        /*if(city && city !== ""){
            params.city = city;
            questions = [{question:66,size:12},{question:64,size:12},{question:60,size:12},{question:68,size:6},{question:62,size:6},{question:61,size:12}];
        }*/

       
        return questions.map(({question,size})=>
        {
            let graph = this.props.formData.userSelection === "Target Users" ? 
                <MetabaseGraph
                question = {question}
                params = {params}
                key = {question}
                source = {53}
                /> :
                <MetabaseGraph
                    question = {question}
                    key = {question}
                    source = {107}
                />

            return <div className={`col-lg-${size}`}>
                {graph}
            </div>

        }


        );

    }
    
    render(){
        return(
            <>
                <div className={"row"}>
                {this.getGraphs()}
                </div>
            </>
        );

    }


}

const mapStateToProps = (state,ownProps) => {

    let formData = state.forms.targetingForm;
    let merchantId = state.user.id || Cookies.get("merchant");;

    return {
        formData,
        merchantId
    }

}
export default connect(mapStateToProps)(Graphs)
