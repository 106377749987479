import React,{Component} from "react"

import Input from "../../../../Form/Controls/Input";
import Radio from "../../../../Form/Controls/Radio";

class Display extends Component{

    constructor(props) {
        super(props);

        this.state = {

            checkBoxes:["Smart Display Campaign","Standard Display Campaign","Gmail Campaign"]

        }
    }



    render(){
        return(
            <>
                <h5>Select the ways you'd like to reach your goal</h5>

                <Radio options={this.state.checkBoxes} name={"displayOptions"}/>
                    <Input
                        type={"text"}
                        labelText={"Web Address"}
                        name={"webAddress"}
                        placeholder={"http://www.test.com"}
                        className={"form-control"}
                        number={"false"}


                    />


            </>
        )
    }



}

export default Display