import React,{Component} from "react";
import Input from "../../Form/Controls/Input";
import DynamicForm from "../../Form/Controls/DynamicForm";
import DropDown from "../../Form/Controls/DropDown";
import {connect} from "react-redux";
import {getCityList} from "../../../api/api";
import {inputChanged} from "../../../Redux/form/action";

class Demographics extends Component{

    constructor(props) {
        super(props);
        const countries = ["All","Canada","United States"];
        const states = ["All","Alabama","Alaska","Arizona","Arkansas","California","Colorado","Connecticut","Delaware","Florida","Georgia","Hawaii","Idaho","Illinois","Indiana","Iowa","Kansas","Kentucky","Louisiana","Maine","Maryland","Massachusetts","Michigan","Minnesota","Mississippi","Missouri","Montana","Nebraska","Nevada","New Hampshire","New Jersey","New Mexico","New York","North Carolina","North Dakota","Ohio","Oklahoma","Oregon","Pennsylvania","Rhode Island","South Carolina","South Dakota","Tennessee","Texas","Utah","Vermont","Virginia","Washington","West Virginia","Wisconsin","Wyoming"];
        const stateCodes = ["Code","AL","AK","AZ","AR","CA","CO","CT","DE","DC","FL","GA","HI","ID","IL","IN","IA","KS","KY","LA","ME","MD","MA","MI","MN","MS","MO","MT","NE","NV","NH","NJ","NM","NY","NC","ND","OH","OK","OR","PA","RI","SC","SD","TN","TX","UT","VT","VA","WA","WV","WI","WY"];
        const provinces = ["All","Alberta","British Columbia","Manitoba","New Brunswick","Newfoundland and Labrador","Northwest Territories","Nova Scotia","Nunavut","Ontario","Prince Edward Island","Quebec","Saskatchewan","Yukon"];
        const provinceCodes = ["Code","AB","BC","MB","NB","NL","NT","NS","NU","ON","PE","QC","SK","YT"];
        const all = ["All"]
        this.state = {countries,states,stateCodes,provinces,provinceCodes,all,cityName:"",suggestions:[],currentProvince:"All",cities:[]};

        this.onChange = this.onChange.bind(this);
        this.clearSuggestions = this.clearSuggestions.bind(this);
        this.onSuggestHandler = this.onSuggestHandler.bind(this);
    }


    async updateCityList(data){
        const cityList = await getCityList(data);
        const cities = [...cityList]
        this.setState({cities})


    }

    onChange(event){

        let suggestions = [];
        const text = event.target.value;
                if(text.length >0){

                    suggestions = this.state.cities.filter(item =>{
                        const regex = new RegExp(`${text}`,"gi");
                        return item.city.match(regex);
                    });
                    console.log(suggestions)
                }
                let {cityName} = this.state;
                cityName = text;
                this.setState({cityName,suggestions});
                //this.props.inputChanged(cityName);
    }


    clearSuggestions(){

        setTimeout(()=>{

            let {suggestions} = this.state;
            suggestions = [];
            this.setState({suggestions});

        },500)


    }


    onSuggestHandler = (text ) => {
                let {cityName,suggestions} = this.state;
                cityName = text;
                suggestions = [];
                this.props.inputChanged(cityName);
                this.setState({cityName,suggestions})
    }


    render() {

        if(this.props.province && this.props.province !== "All" && this.props.province !== this.state.currentProvince){
            const {country,province} = this.props;
            this.setState({currentProvince:this.props.province})
            const provinceCode = country == this.state.countries[1] ? this.state.provinceCodes[this.state.provinces.indexOf(province)] : this.state.stateCodes[this.state.states.indexOf(province)];
            this.updateCityList({country,province:provinceCode});
        }

        return (
            <>
                <h5>TARGETING - DEMOGRAPHICS & GEOGRAPHY</h5>
                <div className="row">
                    <div className="col-lg-4 mg-t-10 mg-lg-t-0">
                        <Input
                            type={"text"}
                            labelText={"Minimum Age"}
                            name={"minimumAge"}
                            placeholder={"#"}
                            className={"form-control"}
                            number={"true"}
                            parentname={"targetingForm"}
                        />
                    </div>
                    <div className="col-lg-4 mg-t-10 mg-lg-t-0">
                        <Input
                            type={"text"}
                            labelText={"Maximum Age"}
                            name={"maximumAge"}
                            placeholder={"#"}
                            className={"form-control"}
                            number={"true"}
                            parentname={"targetingForm"}

                        />
                    </div>
                    <div className="col-lg-4 mg-t-10 mg-lg-t-0">
                        <DropDown

                            labelText={"Gender"}
                            options={["All","Male","Female","Not Identified"]}
                            name={"gender"}
                            className={"form-control"}
                            parentname={"targetingForm"}


                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-4 mg-t-10 mg-lg-t-0">
                        <DropDown
                            labelText={"Country"}
                            options={this.state.countries}
                            name={"country"}
                            className={"form-control"}
                            parentname={"targetingForm"}
                        />
                    </div>
                    <div className="col-lg-4 mg-t-10 mg-lg-t-0">
                        <DropDown
                            labelText={this.props.country === this.state.countries[0] ? "State/Province" : this.props.country === this.state.countries[1] ? "Province" : "State"}
                            options={this.props.country === this.state.countries[0] ? this.state.all : this.props.country === this.state.countries[1] ? this.state.provinces : this.state.states}
                            name={"province"}
                            className={"form-control"}
                            parentname={"targetingForm"}
                        />
                    </div>
                    <div className="col-lg-4 mg-t-10 mg-lg-t-0">
                        <Input
                            type={"text"}
                            labelText={`City`}
                            placeholder={`City Name`}
                            className={"form-control"}
                            number={"false"}
                            parentname={"targetingForm"}

                            newValue={this.state.cityName}
                            onChange={(event)=>this.onChange(event)}
                            onBlur={()=>this.clearSuggestions()}
                            suggestions = {this.state.suggestions.map((value)=>value.city)}
                            suggestHandler = {this.onSuggestHandler}

                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-4 mg-t-10 mg-lg-t-0">
                    <Input
                            type={"text"}
                            labelText={"Postal Code"}
                            name={"postalCode"}
                            placeholder={"Postal Code"}
                            className={"form-control"}
                            number={"true"}
                            parentname={"targetingForm"}
                        />
                    </div>
                    <div className="col-lg-4 mg-t-10 mg-lg-t-0">
                    <Input
                            type={"number"}
                            labelText={"Radius"}
                            name={"radius"}
                            placeholder={"#"}
                            className={"form-control"}
                            number={"true"}
                            parentname={"targetingForm"}
                        />
                    </div>
                </div>



            </>
        );
    }


}
const mapStateToProps = (state) => {

    const {country,province} = state.forms.targetingForm;

    return {
        country,
        province,
    }

}
const mapDispatchToProps = (dispatch,ownProps) => {
    return {
        inputChanged: (value) => dispatch(inputChanged("targetingForm","city",value)),


    }
}
export default connect(mapStateToProps,mapDispatchToProps)(Demographics)
