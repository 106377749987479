import React,{Component} from "react"



class Shopping extends Component{

    constructor(props) {
        super(props);

        this.state = {

            checkBoxes:["Smart Display Campaign","Standard Display Campaign","Gmail Campaign"]

        }
    }



    render(){
        return(
            <>
                <h5>Enter the countries where your product is sold</h5>
                <textarea rows={3}/>


            </>
        )
    }



}

export default Shopping