import React,{Component} from "react";
import Radio from "../../Form/Controls/Radio"
import OptionalField from "../../Form/Controls/OptionalField";

class DateRangeOptions extends Component{

    constructor(props) {
        super(props);

        this.state = {options:["Date Range","Rolling Lookback"]}

    }


    render(){

        return(
            <>
                <OptionalField targetName={"platformOption"} targetValue={"OvalCash"} parentname={this.props.parentname}>
                <div className={"row"}>
                    <div className={"col"}>
                        <label className={"control-label"} htmlFor={"merchantSpendDateOptions"}>{"Date Range Options"}</label>
                    </div>
                </div>
                <div className="row">
                    <div className={"col-lg"}>
                        <Radio
                            options = {this.state.options}
                            name = {this.props.name}
                            parentname={this.props.parentname}
                        />
                    </div>
                </div>
                </OptionalField>
            </>


        )


    }




}

export default DateRangeOptions