import React, {Component} from 'react';
import DynamicForm from "./Form/Controls/DynamicForm";
import Input from "./Form/Controls/Input";
import { validate } from 'validate.js';
import { connect } from "react-redux"
import {errorMessages} from "../Redux/form/action"
import Cookies from 'js-cookie';
import {browserHistory} from "react-router";
import {login} from "../api/api";

class SignIn extends Component{

    constructor(props,context) {
        super(props,context);

        this.state = {

            formName : "signInForm"

        };

        this.validateForm = this.validateForm.bind(this);

    }

    async validateForm(){

        let constraints = {
            email: {
                presence: {
                    message: "^Please enter your email."
                }
            },
            password: {
                presence: {
                    message: "^Please enter a password."
                }
            },


        }

        let errorMessages = validate(this.props.formData,constraints);

        if(errorMessages === undefined) {

            const {error,token} = await login({email:this.props.formData.email,password:this.props.formData.password});

            if(token){
                Cookies.set("username",token);
                Cookies.set("merchant",885);
                window.location.reload(false);


            }
            else if(error){
                errorMessages = {password:"Invalid Password"}
            }else{
                errorMessages = {email:"Invalid Email"}
            }

        }
        this.props.errorMessages(this.state.formName,errorMessages);


    }

    render(){

        return(
            <div style={styles}>
            <div style={centerBox}>
                <h1 style={{paddingBottom:"20px"}}>
                    Swerve Merchant Login
                </h1>


                <DynamicForm name={this.state.formName} >
                    <div className="row">
                        <div className="col-lg mg-t-10 mg-lg-t-0">
                            <Input
                                type={"email"}
                                labelText={"Email"}
                                name={"email"}
                                placeholder={"name@example.ca"}
                                className={"form-control"}
                                number={"false"}

                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg mg-t-10 mg-lg-t-0">
                            <Input
                                type={"password"}
                                labelText={"Password"}
                                name={"password"}
                                placeholder={"password"}
                                className={"form-control"}
                                number={"false"}
                            />
                        </div>
                    </div>
                    <div className={"row"}>


                            <div className={"col-lg mg-t-10 mg-lg-t-0"}>
                        <button type="button" class="btn btn-primary" onClick={this.validateForm}>Login ></button>
                            </div>
                    </div>



                </DynamicForm>

            </div>
            </div>

        );

    }


}

const styles = {

    backgroundColor:"#1D2939",
    width:"100vw",
    height:"100vh"

}

const centerBox ={
    position: 'absolute', left: '50%', top: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor:"white",
    padding:"50px",
    borderRadius: "10px"



}

const mapStateToProps = (state) => {

    let form = state.forms.signInForm;

    return {
        formData: form
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        errorMessages:(name,value)=>dispatch(errorMessages(name,value)),


    }
}

export default connect(mapStateToProps,mapDispatchToProps)(SignIn)