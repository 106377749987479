import {INPUT_CHANGED,ERROR_MESSAGES} from "./Constants";

export const inputChanged = (form,name,value) =>{

    return{

        form,
        name,
        value,
        type:INPUT_CHANGED

    };


};

export const errorMessages = (form,errorMessages) =>{

    return{
        form,
        errorMessages,
        type:ERROR_MESSAGES
    };

};