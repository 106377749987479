import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import BudgetAndSchedule from "./Components/BudgetAndSchedule";
import Placements from "./Components/Placements";
import Devices from "./Components/Devices";
import Optimization from "./Components/Optimization";

class Messages extends Component {
    constructor(props) {
        super(props);
        this.state= {
            radioOptions:["Click To Message","Sponsored Message"],
            selectedOption:0,
            optionsList: [
                {
                    name:"Messenger",
                    checked:false
                },
                {
                    name:"WhatsApp",
                    checked:false
                },
                {
                    name:"Instagram Direct",
                    checked:false
                },

            ],

        }

        this.setState({selectedOption: this.state.radioOptions[0]});

        this.generateRadioButtons = this.generateRadioButtons.bind(this);


    }


    generateOptions(){

        return this.state.optionsList.map((value,index)=>{

            return  (
                <div className="col-lg-12 mg-t-10 mg-b-20 mg-lg-t-0">

                    <label key={index}>
                        <input
                            type="checkbox"
                            checked={value.checked}
                            onChange={()=>this.handleCheck(value.name)} />
                        &nbsp;&nbsp;{value.name}

                    </label>
                </div>
            );


        });

    }

    generateRadioButtons(){

        return this.state.radioOptions.map((value,index)=>{

            return(
                <div className="radio col-lg-2 mg-t-10 mg-b-20 mg-lg-t-0" key={index}>
                    <label>
                        <input
                            type="radio"
                            value={value}
                            checked={this.state.selectedOption === value}
                            onChange={(e)=>this.onValueChange("selectedOption",e)}
                        />
                        &nbsp;&nbsp;{value}
                    </label>
                </div>
            );

        });
    }



    render(){



        return(

            <>
                <div className={"row"}>
                    <h5>
                        Ad Type
                    </h5>
                </div>
                <br/>
                <div className={"row"}>
                    {this.generateRadioButtons()}
                </div>
                <hr/>
                <div className={"row"}>
                    <h5>
                        Messaging App
                    </h5>
                </div>
                <br/>
                <div className={"row"}>
                    {this.generateOptions()}
                </div>
                <hr/>

                <BudgetAndSchedule/>
                <hr/>
                <Placements/>
                <hr/>
                <Optimization
                    dropDownOptions={["Leads","Link Clicks","Replies"]}
                    radioOptions={["CPI","CPC"]}
                />

            </>

        )


    }


}

export default Messages;
