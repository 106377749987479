import React, {Component} from "react";
import DynamicForm from "../../Form/Controls/DynamicForm";
import OptionalField from "../../Form/Controls/OptionalField";
import Input from "../../Form/Controls/Input";
import Demographics from "./Demographics";
import Competitor from "./Competitor";
import Spend from "./Spend";
import HorizontalRule from "../../Elements/HorizontalRule";
import Purchases from "./Purchases";
import Graphs from "./Graphs";
import FacebookQuestions from "./FacebookQuestions";
import GoogleQuestions from "./GoogleQuestions";
import SecondPage from "./GoogleSub/SecondPage";
import PlatformChoice from "./PlatformChoice"
import CampaignDetails from "./CampaignDetails";
import CampaignReward from "./CampaignReward";
import {errorMessages} from "../../../Redux/form/action";
import {connect} from "react-redux";
import SubmitCampaign from "./SubmitCampaign";
import {validate} from "validate.js";
import UserSelection from "./UserSelection";


class NewCampaign extends Component{

    constructor(props) {
        super(props);

        this.state = {
            page:0,
            formName : "targetingForm"

        }

        this.changePage = this.changePage.bind(this)


    }

    changePage(page){

        window.scroll({
            top: 0,
            left: 0,
        });
        this.setState({page})

    }




    async validateForm() {

        let constraints = {
            campaignID: {
                presence: {
                    message: "^Please enter a Campaign Id."
                }
            },


        }

        let errorMessages = validate(this.props.formData,constraints);

        if(errorMessages === undefined) {

            this.changePage(1);

        }
        this.props.errorMessages(this.state.formName,errorMessages);

    }



    render(){

        switch(this.state.page) {

            case 0:
                return (
                    <>

                        <h3>Create New Campaign</h3>

                        <div style={styles}>

                            <DynamicForm name={"targetingForm"}>
                                <PlatformChoice/>
                                <HorizontalRule/>

                                <div className="row">
                                    <div className={"col-lg-11"}></div>

                                    <div className={"col-lg-1 mg-t-10 mg-lg-t-0"} style={{}}>
                                        <button type="button" className="btn btn-primary"
                                                onClick={() => this.validateForm()}>Visualize
                                        </button>
                                    </div>

                                </div>

                                <div className="row">
                                    <div className="col-lg mg-t-10 mg-lg-t-0">
                                        <Input
                                            type={"text"}
                                            labelText={"Campaign ID"}
                                            name={"campaignID"}
                                            placeholder={"Campaign ID"}
                                            className={"form-control"}
                                            number={"false"}
                                        />
                                    </div>
                                </div>
                                <HorizontalRule/>
                                <UserSelection/>
                                <HorizontalRule/>
                                <OptionalField targetName={"userSelection"} targetValue={"Target Users"}>
                                    <Demographics/>
                                    <HorizontalRule/>
                                    <Competitor

                                    />
                                    <HorizontalRule/>
                                    <Spend/>
                                    <HorizontalRule/>
                                    <Purchases/>
                                    <HorizontalRule/>
                                </OptionalField>
                                <OptionalField targetName={"platformOption"} targetValue={"OvalCash"}>
                                    <CampaignReward/>
                                    <HorizontalRule/>
                                    <CampaignDetails/>
                                    <HorizontalRule/>
                                </OptionalField>
                                <div className="row">
                                    <div className={"col-lg-11"}></div>

                                    <div className={"col-lg-1 mg-t-10 mg-lg-t-0"} style={{}}>
                                        <button type="button" className="btn btn-primary"
                                                onClick={() => this.validateForm()}>Visualize
                                        </button>
                                    </div>

                                </div>

                            </DynamicForm>
                        </div>
                    </>
                );

            case 1:
                return( <>

                        <div className="row">
                            <div className={"col-lg-1 mg-t-10 mg-lg-t-0"} style={{}}>
                                <button type="button" className="btn btn-primary"
                                        onClick={() => this.changePage(0)}>Edit
                                </button>
                            </div>

                            <div className={"col-lg-10"}></div>
                            <div className={"col-lg-1 mg-t-10 mg-lg-t-0"} style={{}}>
                                <button type="button" className="btn btn-primary"
                                        onClick={() => this.changePage(this.props.platformOption === "OvalCash" ? 3 : 2)}>Submit
                                </button>
                            </div>
                        </div>

                    <Graphs/>
                        <div className="row">
                            <div className={"col-lg-1 mg-t-10 mg-lg-t-0"} style={{}}>
                                <button type="button" className="btn btn-primary"
                                        onClick={() => this.changePage(0)}>Edit
                                </button>
                            </div>

                            <div className={"col-lg-10"}></div>
                            <div className={"col-lg-1 mg-t-10 mg-lg-t-0"} style={{}}>
                                <button type="button" className="btn btn-primary"
                                        onClick={() => this.changePage(this.props.platformOption === "OvalCash" ? 3 : 2)}>Submit
                                </button>
                            </div>
                        </div>
                    </>
                );
            case 2:
                return (
                    <>
                    <h3>Create New Campaign</h3>

                <div style={styles}>

                    <div className="row">
                        <div className={"col-lg-1 mg-t-10 mg-lg-t-0"} style={{}}>
                            <button type="button" className="btn btn-primary"
                                    onClick={() => this.changePage(1)}>Back
                            </button>
                        </div>

                        <div className={"col-lg-9"}></div>
                        <div className={"col-lg-2 mg-t-10 mg-lg-t-0"} style={{}}>
                            <button type="button" className="btn btn-primary"
                                    onClick={() => this.changePage(this.props.platformOption === "Social" ? 3 : 4)}>Next Page
                            </button>
                        </div>
                    </div>
                    <hr/>
                    {this.props.platformOption === "Search" || this.props.platformOption === "Display" ? <GoogleQuestions/> : <FacebookQuestions/>}

                        <div className="row">
                            <div className={"col-lg-1 mg-t-10 mg-lg-t-0"} style={{}}>
                                <button type="button" className="btn btn-primary"
                                        onClick={() => this.changePage(1)}>Back
                                </button>
                            </div>

                            <div className={"col-lg-9"}></div>
                            <div className={"col-lg-2 mg-t-10 mg-lg-t-0"} style={{}}>
                                <button type="button" className="btn btn-primary"
                                        onClick={() => this.changePage(this.props.platformOption === "Social" ? 3 : 4)}>Next Page
                                </button>
                            </div>
                        </div>

                        </div>
                    </>
                );
            case 3:
                return (
                    <div style={styles}>
                        <SubmitCampaign/>
                </div>
                );

            case 4:
                return(
                    <>
                        <h3>Create New Campaign</h3>

                        <div style={styles}>

                            <div className="row">
                                <div className={"col-lg-1 mg-t-10 mg-lg-t-0"} style={{}}>
                                    <button type="button" className="btn btn-primary"
                                            onClick={() => this.changePage(2)}>Back
                                    </button>
                                </div>

                                <div className={"col-lg-9"}></div>
                                <div className={"col-lg-2 mg-t-10 mg-lg-t-0"} style={{}}>
                                    <button type="button" className="btn btn-primary"
                                            onClick={() => this.changePage(3)}>Launch Campaign
                                    </button>
                                </div>
                            </div>

                            <hr/>

                            <SecondPage/>
                            <div className="row">
                                <div className={"col-lg-1 mg-t-10 mg-lg-t-0"} style={{}}>
                                    <button type="button" className="btn btn-primary"
                                            onClick={() => this.changePage(2)}>Back
                                    </button>
                                </div>

                                <div className={"col-lg-9"}></div>
                                <div className={"col-lg-2 mg-t-10 mg-lg-t-0"} style={{}}>
                                    <button type="button" className="btn btn-primary"
                                            onClick={() => this.changePage(3)}>Launch Campaign
                                    </button>
                                </div>
                            </div>

                        </div>
                    </>
                    )
                default:
                return <></>
        }
    }


}

const styles = {

    backgroundColor:"white",
    padding:"50px"

}

const mapStateToProps = (state) => {

    let platformOption = state.forms?.targetingForm?.platformOption;
    let formData = state.forms.targetingForm;




    return {
        platformOption: platformOption ?? "OvalCash",
        formData
    }

}

const mapDispatchToProps = (dispatch) => {
    return {
        errorMessages:(name,value)=>dispatch(errorMessages(name,value)),


    }
}


export default connect(mapStateToProps,mapDispatchToProps)(NewCampaign)


