import React, {Component, useState} from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams
} from "react-router-dom";
import MetabaseGraph from "../NewCampaign/MetabaseGraph";
function Graphs(){

        let { id } = useParams();
        let [graphNum,changeGraphNum] = useState(0);


        return(
            <>
                <div className={"row"}>
                    <div className={"col-lg-6"}>
                        <h3>Campaign Reporting</h3>
                    </div>
                    <div className={"col-lg-2 mg-t-10 mg-lg-t-0"} style={{}}>
                        <button type="button" className="btn btn-primary"
                                onClick={() => changeGraphNum(0)}>Report
                        </button>
                    </div>
                    <div className={"col-lg-2 mg-t-10 mg-lg-t-0"} style={{}}>
                        <button type="button" className="btn btn-primary"
                                onClick={() => changeGraphNum(1)}>Purchase
                        </button>
                    </div>
                    <div className={"col-lg-2 mg-t-10 mg-lg-t-0"} style={{}}>
                        <button type="button" className="btn btn-primary"
                                onClick={() => changeGraphNum(2)}>No Purchase
                        </button>
                    </div>
                </div>
                <hr/>
                <h5>{`Campaign Id: ${id}`}</h5>
                <h5>Start Date: 06-13-2021</h5>
                <hr/>
                <div className={"row"}>
                    {getGraphs(id,graphNum)}
                </div>
                <hr/>
                <div className={"row"}>
                    <div className={"col-10"}></div>
                    <div className={"col-lg-2 mg-t-10 mg-lg-t-0"} style={{}}>
                        <button type="button" className="btn btn-primary">Extract & Target
                        </button>
                    </div>
                </div>

            </>
        )
    }

const getGraphs = (campaignId,graphNum) =>{

    const params = {
        campaignId
    };

    let questionsList =
        [
        [{question:55,size:12},{question:56,size:12},{question:57,size:12},{question:58,size:6},{question:59,size:6}],
        [{question:73,size:12},{question:69,size:12},{question:72,size:12},{question:70,size:6},{question:71,size:6}],
            [{question:78,size:12},{question:74,size:12},{question:77,size:12},{question:75,size:6},{question:76,size:6}],
        ]


    const questions = questionsList[graphNum];

    return questions.map(({question,size})=>

        <div className={`col-lg-${size}`}>
            <MetabaseGraph
                question = {question}
                params = {params}
                key = {question}
            />
        </div>


    );

}


export default Graphs