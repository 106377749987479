import {ERROR_MESSAGES, INPUT_CHANGED} from "./Constants";

const reducer = (state = {},action)=>{

    switch (action.type){

        case INPUT_CHANGED:
            console.log(`${action.name} from form ${action.form} changed to ${action.value}`)
            let form = state[action.form];
            form = form || {};

            let item = {[action.name]:action.value};
            form = {...form, ...item}

            return{
                ...state,[action.form]:form
            };

        case ERROR_MESSAGES:
            let form2 = state[action.form];
            form2 = form2 || {};
            form2 = {...form2,errorMessages:action.errorMessages};
            return{
                ...state,[action.form]:form2
            };

        default: return state;

    }


}
export default reducer;