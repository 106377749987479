import React, { Component } from "react";
import Select from "../../../forms/Select";

class Optimization extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedDropDownOption:this.props.dropDownOptions[0],
            selectedOption:this.props.radioOptions[0]
        }

        this.inputChangeHandler = this.inputChangeHandler.bind(this);
        this.onValueChange = this.onValueChange.bind(this);
        this.generateRadioButtons = this.generateRadioButtons.bind(this);
        this.generateOptions = this.generateOptions.bind(this);

    }

    inputChangeHandler(event) {
        this.setState({ [event.target.name]: event.target.value });

    }

    onValueChange(name,event) {
        this.setState({
            [name]: event.target.value
        });
    }

    generateRadioButtons(){

        return this.props.radioOptions.map((value,index)=>{

            return(
            <div className="radio col-lg-2 mg-t-10 mg-b-20 mg-lg-t-0" key={index}>
                <label>
                    <input
                        type="radio"
                        value={value}
                        checked={this.state.selectedOption === value}
                        onChange={(e)=>this.onValueChange("selectedOption",e)}
                    />
                    &nbsp;&nbsp;{value}
                </label>
            </div>
            );

        });
    }



    generateOptions(){

        return this.props.dropDownOptions.map((item,index)=>{
            return(
                <div className="radio col-lg-10 mg-t-10 mg-b-20 mg-lg-t-0" key={index}>

                                <label>
                                    <input
                                        type="radio"
                                        value={item}
                                        checked={this.state.selectedDropDownOption === item}
                                        onChange={(e)=>this.onValueChange("selectedDropDownOption",e)}
                                    />
                                    &nbsp;&nbsp;{item}
                                </label>

                </div>
                        );





        });


    }


    render(){



        return(

            <>
                <div className={"row"}>
                    <h5>
                        Optimization and Delivery
                    </h5>
                </div>
                <br/>
                <div className={"row"}>
                    {this.generateOptions()}
                </div>
                <br/>
                <div className={"row"}>
                    <h5>When You Get Charged </h5>
                </div>
                <div className={"row"}>
                    {this.generateRadioButtons()}

                </div>

            </>

        )


    }


}

export default Optimization;
