import './App.css';
import React from "react"
import Layout from "./Components/Layout";
import { Provider } from 'react-redux';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';


import store from './Redux/store';

function App() {
  return (
      <Provider store={store}>
        <div className="App collapsed-menu">
          <Layout/>
        </div>
      </Provider>

  );
}

export default App;
