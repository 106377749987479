import React, {Component} from "react";
import { BrowserRouter } from "react-router-dom";
import { connect } from "react-redux"
import Router from "../Router";
import LeftPanel from "./LeftPanel";
import Header from "./Header";
import SignIn from "../SignIn";
import Cookies from 'js-cookie';


class Layout extends Component{

    constructor(props) {
        super(props);
    }


    renderRouter(){

        const loggedIn = Cookies.get("username") !== undefined;


        if(!loggedIn){

            return(
              <>
                  <SignIn/>
              </>
            );

        }else{

            return (
                <>

                    <Header/>
                    <div className={"row"}>
                        <div className={"col-2"} style={{marginTop:"60px"}}>
                            <LeftPanel/>
                        </div>
                        <div style={styles} className={"col-10"}>
                            <Router/>
                        </div>
                    </div>
                </>
            )

        }


    }

    render(){

        return(
            <BrowserRouter>

                {this.renderRouter()}

            </BrowserRouter>

            );

    }


}

const styles = {
    backgroundColor:"#E9ECEF",
    marginTop:"60px"
}

const mapStateToProps = (state) => {

    //let isLoggedIn = state.user && this.user.name;

   // return{isLoggedIn}

}


export default connect(mapStateToProps)(Layout);

