import React,{Component} from "react";
import { connect } from "react-redux"
import Input from "../../Form/Controls/Input";
import DropDown from "../../Form/Controls/DropDown";
import {getMerchantList} from "../../../api/api";
import {inputChanged} from "../../../Redux/form/action";
import Cookies from "js-cookie";

let refresh = false;
class Competitor extends Component{


    constructor(props) {
        super(props);
        this.state ={
            typeOptions:["Competitors", "Categories"],
            merchants:[[],[]]

        };
        const arrays = [];
        for(let index in this.state.typeOptions) {
            let arr = [];
            for(let i = 0; i<3; i++){
                arr.push({name:'',suggestions:[],id:-1})
            }
            arrays.push(arr);

        }


        if(this.props.arrays[0].length === 0) {
            this.props.inputChanged(arrays);
        }

        this.onChange = this.onChange.bind(this);
        this.addBox = this.addBox.bind(this);
        this.clearSuggestions = this.clearSuggestions.bind(this);
        this.onSuggestHandler = this.onSuggestHandler.bind(this);




    }


    componentDidMount(){
        this.updateMerchantList();

    }


    async updateMerchantList(){
        const merchantList = await getMerchantList();
        const merchants = [merchantList,[]]
        this.setState({merchants})


    }

    onChange(event,id){

        let suggestions = [];
        const text = event.target.value;


        for(let index in this.state.typeOptions){
            if(this.state.typeOptions[index] === this.props.selectedValue) {

                if(text.length >0){

                    suggestions = this.state.merchants[index].filter(merchant =>{

                        const regex = new RegExp(`${text}`,"gi");
                        return merchant.name.match(regex);

                    })
                }

                let {arrays} = this.props;
                arrays[index][id] = {name:text,suggestions,id:-1};
                this.props.inputChanged(arrays);
                return;

            }
        }
    }

    addBox(){
        for(let index in this.state.typeOptions){
            if(this.state.typeOptions[index] === this.props.selectedValue) {
                let {arrays} = this.props;
                arrays[index].push({name:"",suggestions:[],id:-1});
                this.props.inputChanged(arrays);
                return;

            }
        }
    }

    clearSuggestions(key){

        setTimeout(()=>{

            for(let index in this.state.typeOptions) {
                if (this.state.typeOptions[index] === this.props.selectedValue) {
                    let {arrays} = this.props;
                    arrays[index][key].suggestions = [];
                    this.props.inputChanged(arrays);
                }
            }


        },500)


    }


    onSuggestHandler = (key,text ) => {

        for(let index in this.state.typeOptions) {
            if (this.state.typeOptions[index] === this.props.selectedValue) {
                const {arrays} = this.props;
                arrays[index][key].name = text;
                arrays[index][key].suggestions = [];
                for(let index2 in this.state.merchants){
                    let merchantArray = this.state.merchants[index2];
                    for(let merchant of merchantArray) {
                        console.log(`${merchant.name} ${text}`)
                        if (merchant.name == text) {
                            arrays[index][key].id = merchant.id;

                        }
                    }
                }
                this.props.inputChanged(arrays);
            }
        }

    }

    genBoxes(){
        for(let index in this.state.typeOptions){

            if(this.state.typeOptions[index] === this.props.selectedValue){

                let type = this.state.typeOptions[index];
               return this.props.arrays[index].map((item,key)=>{
                    return(<div className={"col-lg-4 mg-t-10 mg-lg-t-0"} key={key}>

                        <Input
                            type={"text"}
                            labelText={`${type} #${key+1}`}
                            placeholder={`${type} Name`}
                            className={"form-control"}
                            number={"false"}
                            parentname={"targetingForm"}

                            newValue={item.name}
                               onChange={(event)=>this.onChange(event,key)}
                            onBlur={()=>this.clearSuggestions(key)}
                            suggestions = {item.suggestions.map((value)=>value.name)}
                            suggestHandler = {this.onSuggestHandler.bind(this,key)}


                        />

                    </div>);

                });

            }

        }

    }

    render() {
        return (
            <>
                <h5>TARGETING - COMPETITOR OR CATEGORY SELECTION</h5>
                <div className="row">
                    <div className="col-lg-4 mg-t-10 mg-lg-t-0">
                        <DropDown

                            labelText={"Type"}
                            options={this.state.typeOptions}
                            name={"targetOption"}
                            className={"form-control"}
                            parentname={"targetingForm"}


                        />
                    </div>
                </div>
                <div className="row">
                    {this.genBoxes()}
                </div>
                <div className="row">
                    <div className={"col-lg mg-t-10 mg-lg-t-0"} style={{paddingBottom:"20px"}}>
                        <button type="button" className="btn btn-primary" onClick={this.addBox}>{`Add Extra ${this.props.selectedValue}`}</button>
                    </div>

                </div>

            </>
        );
    }


}

const mapStateToProps = (state,ownProps) => {

    let form = state.forms.targetingForm;
    let selectedValue = form ? form.targetOption : undefined;

    let merchantName = Cookies.get("merchantName") ?? (state.user && state.user.name ? state.user.name : "Delete 7");
    let arrays = state.forms?.targetingForm?.competitorList;
    refresh = !refresh;


    return {
        selectedValue,
        merchantName,
        arrays:arrays|| [[],[]],
        refresh
    }

}
const mapDispatchToProps = (dispatch,ownProps) => {
    return {
        inputChanged: (value) => dispatch(inputChanged("targetingForm","competitorList",value)),


    }
}
export default connect(mapStateToProps,mapDispatchToProps)(Competitor)


