import React, {Component} from 'react'
import { connect } from "react-redux"
import MetabaseGraph from "../Campaign/NewCampaign/MetabaseGraph";
import Input from "../Form/Controls/Input";
import {getMerchantList} from "../../api/api"
import DatePicker from "../Form/Controls/DatePicker";
import Cookies from 'js-cookie';

class Dashboard extends Component{

    constructor() {
        super();

        let startDate = new Date();
        startDate.setFullYear(startDate.getFullYear()-2)

        this.state ={
            typeOptions:["Competitor", "Category"],
            arrays:[],
            refresh:true,
            merchants:[],
            startDate:startDate,
            endDate:new Date()

        };
        for(let index in this.state.typeOptions) {
            let arr = [];

            arr.push({name:'',suggestions:[]})
            arr.push({name:'',suggestions:[]})


            this.state.arrays.push(arr);

        }





        this.handleOnChange = this.handleOnChange.bind(this);
        this.addBox = this.addBox.bind(this);
        this.clearSuggestions = this.clearSuggestions.bind(this);
        this.onSuggestHandler = this.onSuggestHandler.bind(this);

        this.updateMerchantList();

        this.onValueChange = this.onValueChange.bind(this);
    }

    onValueChange(key,date) {
        this.setState({[key]:date})
    }

    async updateMerchantList(){
        const merchants = await getMerchantList();
        console.log(merchants)
        this.setState({merchants})


    }


    formatDate(date) {
        let d = date ? new Date(date) : new Date(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    getGraphs(){
        const competitors  = [this.props.merchantId]
        for(let index in this.state.arrays[0]){

            const {name} = this.state.arrays[0][index];

            for(let index2 in this.state.merchants){

                const merchant = this.state.merchants[index2];


                if(name == merchant.name){
                    competitors.push(merchant.id)
                }




            }


        }





        const params = {

            //"targetMerchant": this.props.merchantId,
            "targetMerchant": competitors,
            "targetMerchantFreqRange": this.formatDate(this.state.startDate)+"~"+(this.formatDate(this.state.endDate)),






        };

        const questions = [{question:45,size:12},{question:39,size:12},{question:43,size:12},{question:42,size:12},{question:46,size:12},{question:40,size:12}];
        return questions.map(({question,size})=>

            <div className={`col-lg-${size}`}>
                <MetabaseGraph
                    question = {question}
                    params = {params}
                    key = {question}
                />
            </div>


        );

    }

    addBox(){
        let {arrays} = this.state;
        arrays[0].push({name:"",suggestions:[]});
        this.setState({arrays});

    }

    clearSuggestions(index){

        setTimeout(()=>{

            const {arrays} = this.state;
            arrays[0][index].suggestions = [];
            this.setState({arrays,refresh: !this.state.refresh})


        },500)


    }


    onSuggestHandler = (index,text ) => {
        const {arrays} = this.state;
        arrays[0][index].name = text;
        arrays[0][index].suggestions = [];
        this.setState({refresh: !this.state.refresh,arrays})

    }
    
    handleOnChange(event,index){
        let suggestions = [];
        const text = event.target.value;

        if(text.length >0){

            suggestions = this.state.merchants.filter(merchant =>{

                const regex = new RegExp(`${text}`,"gi");
                return merchant.name.match(regex);

            })
        }


        const {arrays} = this.state;
        arrays[0][index].name = event.target.value;
        arrays[0][index].suggestions = suggestions;

        this.setState({arrays, refresh: !this.state.refresh});

        
        
    }

    genBoxes(){
        for(let index in this.state.typeOptions){

            if(this.state.typeOptions[index] === this.state.typeOptions[0]){

                let type = this.state.typeOptions[index];
                return this.state.arrays[index].map((item,key)=>{
                    return(<div className={"col-lg-4 mg-t-10 mg-lg-t-0"} key={key}>

                        <Input
                            type={"text"}
                            labelText={`${type} #${key+1}`}
                            placeholder={`${type} Name`}
                            className={"form-control"}
                            number={"false"}
                            parentname={"targetingForm"}
                            name={key}
                            newValue={item.name}
                            onChange={(event) => this.handleOnChange(event,key)}
                            onBlur={()=>this.clearSuggestions(key)}
                            suggestions = {item.suggestions.map((value)=>value.name)}
                            suggestHandler = {this.onSuggestHandler.bind(this,key)}



                        />

                    </div>);

                });

            }

        }

    }

    render(){
        return(
            <>
                <h3>Dashboard</h3>
                <div className="row">
                    {this.genBoxes()}
                </div>
                <div className="row">
                    <div className={"col-lg mg-t-10 mg-lg-t-0"} style={{paddingBottom:"20px"}}>
                        <button type="button" className="btn btn-primary" onClick={this.addBox}>{`Add Extra Competitor`}</button>
                    </div>

                </div>
                <div className="row">
                <div className="col-lg-2 mg-t-10 mg-lg-t-0">
                    <DatePicker
                        labelText={"Start Date"}
                        name={"compCatPurchaseStartDate"}
                        className={"form-control"}
                        parentname={"targetingForm"}
                        startDate={this.state.startDate}
                        onChange={date => this.onValueChange("startDate",date)}



                    />

                </div>
                <div className="col-lg-2 mg-t-10 mg-lg-t-0">
                    <DatePicker
                        labelText={"End Date"}
                        name={"compCatPurchaseEndDate"}
                        className={"form-control"}
                        parentname={"targetingForm"}
                        startDate={this.state.endDate}
                        onChange={date => this.onValueChange("endDate",date)}


                    />
                </div>
                </div>

                <div className={"row"}>
                    {this.getGraphs()}
                </div>
            </>
        );

    }


}

const mapStateToProps = (state,ownProps) => {

    let merchantId = Cookies.get("merchant")|| state.user.id;




    return {
        merchantId
    }

}
export default connect(mapStateToProps)(Dashboard)
