import React,{Component} from "react";
import {DateRangePicker} from 'react-dates';
import moment from 'moment';
import DatePicker from "../../Form/Controls/DatePicker";
import DropDown from "../../Form/Controls/DropDown";
import { connect } from "react-redux";
import Input from "../../Form/Controls/Input";
import HorizontalRule from "../../Elements/HorizontalRule";
import DynamicForm from "../../Form/Controls/DynamicForm";
import DateRangeOptions from "./DateRangeOptions";
import OptionalField from "../../Form/Controls/OptionalField";
import Cookies from "js-cookie";


class Purchases extends Component{

    constructor(props) {
        super(props);

        this.state = {

            options:["Between Purchases","Greater Than","Less Than"]

        };

    }

    render() {

        return (
            <>

                <h5>Number of Purchases at {this.props.merchantName}</h5>
                <div className={"row"}>
                    <div className={"col"}>
                        <DateRangeOptions name={"merchantPurchaseDateOptions"} parentname={this.props.parentname}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-2 mg-t-10 mg-lg-t-0">
                        <DropDown

                            labelText={"Type"}
                            options={this.state.options}
                            name={"merchPurchaseRangeOption"}
                            className={"form-control"}
                            parentname={"targetingForm"}


                        />
                    </div>
                    <OptionalField targetName={"merchPurchaseRangeOption"} targetValue={"Between Purchases"} parentname={this.props.parentname}>
                    <div className="col-lg-3 mg-t-10 mg-lg-t-0">
                        <Input
                            type={"number"}
                            labelText={"Minimum Number of Purchases"}
                            name={"merchPurchaseMin"}
                            placeholder={"#"}
                            className={"form-control"}
                            parentname={"targetingForm"}

                        />
                    </div>
                    <div className="col-lg-3 mg-t-10 mg-lg-t-0">
                        <Input
                            type={"number"}
                            labelText={"Maximum Number of Purchases"}
                            name={"merchPurchaseMax"}
                            placeholder={"#"}
                            className={"form-control"}
                            parentname={"targetingForm"}

                        />
                    </div>
                    </OptionalField>

                    <OptionalField targetName={"merchPurchaseRangeOption"} targetValue={"Greater Than"} parentname={this.props.parentname}>
                        <div className="col-lg-3 mg-t-10 mg-lg-t-0">
                            <Input
                                type={"number"}
                                labelText={"Minimum Number of Purchases"}
                                name={"merchPurchaseMin"}
                                placeholder={"#"}
                                className={"form-control"}
                                parentname={"targetingForm"}

                            />
                        </div>
                    </OptionalField>

                    <OptionalField targetName={"merchPurchaseRangeOption"} targetValue={"Less Than"} parentname={this.props.parentname}>
                        <div className="col-lg-3 mg-t-10 mg-lg-t-0">
                            <Input
                                type={"number"}
                                labelText={"Maximum Number of Purchases"}
                                name={"merchPurchaseMax"}
                                placeholder={"#"}
                                className={"form-control"}
                                parentname={"targetingForm"}

                            />
                        </div>
                    </OptionalField>

                    <OptionalField targetName={"merchantPurchaseDateOptions"} targetValue={"Date Range"} parentname={this.props.parentname}>
                        <div className="col-lg-2 mg-t-10 mg-lg-t-0">
                            <DatePicker
                                labelText={"Start Date"}
                                name={"merchPurchaseStartDate"}
                                className={"form-control"}
                                parentname={"targetingForm"}


                            />

                        </div>
                        <div className="col-lg-2 mg-t-10 mg-lg-t-0">
                            <DatePicker
                                labelText={"End Date"}
                                name={"merchPurchaseEndDate"}
                                className={"form-control"}
                                parentname={"targetingForm"}

                            />

                        </div>
                    </OptionalField>

                    <OptionalField targetName={"merchantPurchaseDateOptions"} targetValue={"Rolling Lookback"} parentname={this.props.parentname}>
                        <div className="col-lg-3 mg-t-10 mg-lg-t-0">
                            <Input
                                type={"number"}
                                labelText={"Number of Days"}
                                name={"merchantPurchaseNumberOfDays"}
                                placeholder={"30"}
                                className={"form-control"}
                                parentname={"targetingForm"}


                            />
                        </div>
                    </OptionalField>

                </div>

                <HorizontalRule/>

                <h5>Number of Purchases at {this.props.selectedValue}</h5>
                <div className={"row"}>
                    <div className={"col"}>
                        <DateRangeOptions name={"competitorPurchaseDateOptions"} parentname={this.props.parentname}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-2 mg-t-10 mg-lg-t-0">
                        <DropDown

                            labelText={"Type"}
                            options={this.state.options}
                            name={"compCatPurchaseRangeOption"}
                            className={"form-control"}
                            parentname={"targetingForm"}


                        />
                    </div>
                    <OptionalField targetName={"compCatPurchaseRangeOption"} targetValue={"Between Purchases"} parentname={this.props.parentname}>
                    <div className="col-lg-3 mg-t-10 mg-lg-t-0">
                        <Input
                            type={"number"}
                            labelText={"Minimum Number of Purchases"}
                            name={"compCatPurchaseMin"}
                            placeholder={"#"}
                            className={"form-control"}
                            parentname={"targetingForm"}

                        />
                    </div>
                    <div className="col-lg-3 mg-t-10 mg-lg-t-0">
                        <Input
                            type={"number"}
                            labelText={"Maximum Number of Purchases"}
                            name={"compCatPurchaseMax"}
                            placeholder={"#"}
                            className={"form-control"}
                            parentname={"targetingForm"}

                        />
                    </div>
                    </OptionalField>

                    <OptionalField targetName={"compCatPurchaseRangeOption"} targetValue={"Greater Than"} parentname={this.props.parentname}>
                        <div className="col-lg-3 mg-t-10 mg-lg-t-0">
                            <Input
                                type={"number"}
                                labelText={"Minimum Number of Purchases"}
                                name={"compCatPurchaseMin"}
                                placeholder={"#"}
                                className={"form-control"}
                                parentname={"targetingForm"}

                            />
                        </div>
                    </OptionalField>

                    <OptionalField targetName={"compCatPurchaseRangeOption"} targetValue={"Less Than"} parentname={this.props.parentname}>
                        <div className="col-lg-3 mg-t-10 mg-lg-t-0">
                            <Input
                                type={"number"}
                                labelText={"Maximum Number of Purchases"}
                                name={"compCatPurchaseMax"}
                                placeholder={"#"}
                                className={"form-control"}
                                parentname={"targetingForm"}

                            />
                        </div>
                    </OptionalField>

                    <OptionalField targetName={"competitorPurchaseDateOptions"} targetValue={"Date Range"} parentname={this.props.parentname}>
                        <div className="col-lg-2 mg-t-10 mg-lg-t-0">
                            <DatePicker
                                labelText={"Start Date"}
                                name={"compCatPurchaseStartDate"}
                                className={"form-control"}
                                parentname={"targetingForm"}


                            />

                        </div>
                        <div className="col-lg-2 mg-t-10 mg-lg-t-0">
                            <DatePicker
                                labelText={"End Date"}
                                name={"compCatPurchaseEndDate"}
                                className={"form-control"}
                                parentname={"targetingForm"}

                            />

                        </div>
                    </OptionalField>

                    <OptionalField targetName={"competitorPurchaseDateOptions"} targetValue={"Rolling Lookback"} parentname={this.props.parentname}>
                        <div className="col-lg-3 mg-t-10 mg-lg-t-0">
                            <Input
                                type={"number"}
                                labelText={"Number of Days"}
                                name={"competitorPurchaseNumberOfDays"}
                                placeholder={"30"}
                                className={"form-control"}
                                parentname={"targetingForm"}


                            />
                        </div>
                    </OptionalField>

                </div>



            </>
        );
    }


}


const mapStateToProps = (state,ownProps) => {

    let form = state.forms.targetingForm;
    let selectedValue = form ? form.targetOption : undefined;

    let merchantName = Cookies.get("merchantName") ?? (state.user && state.user.name ? state.user.name : "Delete 7");

    return {
        selectedValue,
        merchantName
    }

}
export default connect(mapStateToProps)(Purchases);