import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import BudgetAndSchedule from "./Components/BudgetAndSchedule";
import Placements from "./Components/Placements";
import Devices from "./Components/Devices";
import Optimization from "./Components/Optimization";

class VideoViews extends Component {
    constructor(props) {
        super(props);
        this.state= {
            yesNo:["Yes","No"],
            dynamicCreative:0

        }

        this.inputChangeHandler = this.inputChangeHandler.bind(this);


    }

    inputChangeHandler(event) {
        this.setState({ [event.target.name]: event.target.value });

    }



    render(){



        return(

            <>
                <BudgetAndSchedule/>
                <hr/>
                <Placements/>
                <hr/>
                <Optimization
                    dropDownOptions={["Through Play", "Two Second Continuous Views"]}
                    radioOptions={["Through Play","Impressions"]}
                />
            </>

        )


    }


}

export default VideoViews;
