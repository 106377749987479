import React,{Component} from "react";
import { connect } from "react-redux"
import HorizontalRule from "../Elements/HorizontalRule";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import ReactDOMServer from 'react-dom/server';


class APIKeys extends Component{


    render(){

        const element =  "<div style=\"position:relative;\">\n" +
            " <object data=\"http://theplumapp.com/api/getMerchantImage/?token=cdcfb1ac5896c6cb954a4275e3a9739fa7c5771c442c77c7e2160b039f88114d\" type=\"image/svg+xml\" height=\"640px\" width=\"640px\"/>\n" +
            "<a href=\"https://www.w3schools.com/\" style=\"position:absolute; top:0; left:0; display:inline-block; width:640px; height:640px; z-index:5;\"></a>\n" +
            "</div>"

        return(

            <>
                <h3>API Keys</h3>

                <div style={styles}>
                    <div className={"row"}>
                        <div className={"col-lg-2"}>
                    <h5 style={{marginTop:"5px"}}>API Key: </h5>
                        </div>
                        <div className={"col-lg-3"}>

                        <input
                                value={this.props.apiToken}
                                disabled
                                className={"form-control"}
                            />
                        </div>
                    </div>
                    <HorizontalRule/>
                    <div className={"row"}>
                        <div className={"col-lg"}>
                            <h5>NodeJS Example(Server)</h5>
                            <SyntaxHighlighter language="javascript" style={docco}>
                                {`
                                var request = require('request');
                                const API_TOKEN = "${this.props.apiToken}"
                                
                                function getImageToken(amount){
                                    const postData = {amount,apiToken:apiToken}
                                    const clientServerOptions = {
                                    uri: 'https://api.plum.com/getImageToken',
                                    body: JSON.stringify(postData),
                                    method: 'POST',
                                    headers: {
                                    'Content-Type': 'application/json'
                                }
                                }
                                    request(clientServerOptions, function (error, response) {
                                    console.log(error,response.body);
                                    return response.body.imageToken;
                                });
                                }`}
                            </SyntaxHighlighter>


                        </div>
                    </div>

                    <HorizontalRule/>
                    <div className={"row"}>
                        <div className={"col-lg"}>
                            <h5>HTML Example(Client)</h5>
                            <SyntaxHighlighter language="html" style={docco}>
                                {
                                    element

                                }
                            </SyntaxHighlighter>

                        </div>
                    </div>

                </div>
            </>

        );


    }


}

const styles = {

    backgroundColor:"white",
    padding:"50px"

}

const mapStateToProps = (state) => {

    let apiToken = state.apiToken || "I9Ytw0wpkE18uqxeDzV1"


    return {
        apiToken
    }

}
export default connect(mapStateToProps)(APIKeys)
