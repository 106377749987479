import React, { Component } from "react";
import {Navigation} from 'react-minimal-side-navigation';
import 'react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css';
import Icon from 'awesome-react-icons';


export default class LeftPanel extends Component {

  constructor(props) {
    super(props);
  }

  render() {

    return (
        <div style={styles}>
          <Navigation
              // you can use your own router's api to get pathname
              activeItemId={window.location.pathname}
              onSelect={({itemId}) => {
                if(itemId !== "")
                window.location.href = itemId;
              }}
              items={[
                {
                  title: 'Dashboard',
                  itemId: '/dashboard',
                  // you can use your own custom Icon component as well
                  // icon is optional
                  elemBefore: () => <Icon name="inbox" />,
                },
                {
                  title: 'Campaigns',
                  itemId: '',
                  elemBefore: () => <Icon name="users" />,
                  subNav: [
                    {
                      title: 'New Campaign',
                      itemId: '/campaigns/new',
                    },
                    {
                      title: 'View Campaigns',
                      itemId: '/campaigns/view',
                    },
                  ],
                },
                {
                  title: 'Invoices',
                  itemId: '/invoices',
                  // you can use your own custom Icon component as well
                  // icon is optional
                  elemBefore: () => <Icon name="send" />,
                },
                {
                  title: 'API Keys',
                  itemId: '/keys',
                  // you can use your own custom Icon component as well
                  // icon is optional
                  elemBefore: () => <Icon name="lock" />,
                },

              ]}
          />
        </div>
    );
  }
}

const styles ={
  position:"static",
  height:"99vh"

}
