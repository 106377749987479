import React, {Component} from "react"
import { connect } from "react-redux"
import {inputChanged} from "../../../Redux/form/action";

class CheckBox extends Component{

    constructor(props) {
        super(props);

        this.state = {rerender:true}

        this.generateRadioButtons = this.generateRadioButtons.bind(this);
        this.onValueChange = this.onValueChange.bind(this);
        const list = this.props.defaultValues ? this.props.defaultValues: this.props.options.map(value => {return {name:value,checked:false}});



        this.props.inputChanged(list);

    }

    onValueChange(event,index) {
        const list = this.props.selectedOption;
        list[index] = {...list[index],checked:!list[index].checked}
        this.props.inputChanged(list);
        this.setState({rerender: !this.state.rerender})
    }


    generateRadioButtons(){
        return this.props.options.map((value,index)=>{

            return(
                <div className={this.props.radioStyle}>
                    <label key={index}>
                        <input
                            type="checkbox"
                            value={value}
                            checked={this.props.selectedOption[index].checked === true}
                            onChange={(event) => this.onValueChange(event,index)}
                        />
                        &nbsp;&nbsp;{value}
                    </label>
                </div>

            );

        });
    }



    render(){

        if(this.props.visible === undefined || this.props.visible === true){

            return(
                <>
                    {this.generateRadioButtons()}
                </>
            );


        }
        else
            return <></>


    }






}

const mapStateToProps = (state,ownProps) => {

    let form = state.forms[ownProps.parentname];
    let field = form ? form[ownProps.name] : undefined;
    let value = field ? field : ownProps.options[0];
    return {
        selectedOption: value
    }
}

const mapDispatchToProps = (dispatch,ownProps) => {
    return {
        inputChanged: (value) => dispatch(inputChanged(ownProps.parentname,ownProps.name,value)),


    }
}


export default connect(mapStateToProps, mapDispatchToProps)(CheckBox)
