import React,{Component} from "react";
import Radio from "../../Form/Controls/Radio"
import {inputChanged} from "../../../Redux/form/action";
import {connect} from "react-redux";
import OptionalField from "../../Form/Controls/OptionalField";
import Input from "../../Form/Controls/Input";
import CheckBox from "../../Form/Controls/CheckBox";

class PlatformChoice extends Component{

    constructor(props) {
        super(props);
        console.log("HERE")
        this.state = {options:["Search","Social","Display","OvalCash"],social:["Twitter","Facebook","Reddit","Tiktok","SnapChat","Instagram"]}
        this.onChange = this.onChange.bind(this);
        this.props.inputChanged("merchantPurchaseDateOptions","Date Range");
        this.props.inputChanged("competitorPurchaseDateOptions","Date Range");
        this.props.inputChanged("merchantSpendDateOptions","Date Range");
        this.props.inputChanged("competitorSpendDateOptions","Date Range");

    }


    onChange(value){
        this.props.inputChanged("merchantPurchaseDateOptions","Date Range");
        this.props.inputChanged("competitorPurchaseDateOptions","Date Range");
        this.props.inputChanged("merchantSpendDateOptions","Date Range");
        this.props.inputChanged("competitorSpendDateOptions","Date Range");
        this.props.inputChanged("platformOption",value.target.value)

    }

    render(){

        return(
            <>
                <div className={"row"}>
                    <div className={"col"}>
                        <h5>Target Platform</h5>
                    </div>
                </div>
                <div className="row">
                    <div className={"col-lg"}>
                        <Radio
                            options = {this.state.options}
                            name = {"platformOption"}
                            parentname={this.props.parentname}
                            onChange ={this.onChange}
                        />
                    </div>
                </div>
                <hr/>
                <OptionalField targetName={"platformOption"} targetValue={"Social"} parentname={this.props.parentname}>
                    <div className="row">
                        <div className={"col-lg mg-t-10 mg-lg-t-0"} style={{paddingBottom:"20px"}}>
                            <CheckBox
                                options={this.state.social}
                                name={"socialOptions"}
                                parentname={this.props.parentname}
                            />
                        </div>

                    </div>
                </OptionalField>

            </>


        )


    }




}

const mapStateToProps = (state,ownProps) => {

 return{
        ...state
 }

}
const mapDispatchToProps = (dispatch,ownProps) => {
    return {
        inputChanged: (field,value) => dispatch(inputChanged("targetingForm",field,value)),


    }
}
export default connect(mapStateToProps,mapDispatchToProps)(PlatformChoice)
