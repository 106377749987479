import {ERROR_MESSAGES, INPUT_CHANGED} from "./Constants";
import Cookies from 'js-cookie';


const reducer = (state = {name:"Fortinos",id:Cookies.get("merchant")},action)=>{

    switch (action.type){

        default: return state;

    }


}
export default reducer;