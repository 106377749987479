import React,{Component} from "react";
import Radio from "../../Form/Controls/Radio";
import OptionalField from "../../Form/Controls/OptionalField";
import Input from "../../Form/Controls/Input";
import TextArea from "../../Form/Controls/TextArea";
import {inputChanged} from "../../../Redux/form/action";
import {connect} from "react-redux";

let refresh = false;
class CampaignReward extends Component{

    constructor(props) {
        super(props);
        this.state = {options:["Product Specific","General CB Offer","Milestones"],cashBackOptions:["Dollar Amount","Percentage"]}
        this.onChange = this.onChange.bind(this);
        this.addBox = this.addBox.bind(this);
        const milestoneAmounts = [{spend:0,receive:0}];
        this.props.inputChanged(milestoneAmounts)
    }

    addBox(){

                let {milestoneAmounts} = this.props;
                milestoneAmounts.push({spend:0,receive:0});
                this.props.inputChanged(milestoneAmounts);



    }
    genBoxes(){
                return this.props.milestoneAmounts.map((item,key)=>{
                    return(
                        <div className={"row"} key={key}>
                        <div className={"col-lg-4 mg-t-10 mg-lg-t-0"} >

                        <Input
                            type={"text"}
                            labelText={`Milestone #${key+1} Spend`}
                            placeholder={`#`}
                            className={"form-control"}
                            number={"true"}
                            parentname={this.props.parentname}
                            newValue={item.spend}
                            onChange={(event)=>this.onChange(event,"spend",key)}



                        />
                        </div>
                            <div className={"col-lg-4 mg-t-10 mg-lg-t-0"}>


                            <Input
                            type={"text"}
                            labelText={`Milestone #${key+1} Get`}
                            placeholder={`#`}
                            className={"form-control"}
                            number={"true"}
                            parentname={this.props.parentname}
                            newValue={item.receive}
                            onChange={(event)=>this.onChange(event,"receive",key)}



                        />
                            </div>
                            <div className={"col-lg-4 mg-t-10 mg-lg-t-0"}>


                            <Radio
                            options = {this.state.cashBackOptions}
                            name = {`Milestone${key+1}`}
                        />
                            </div>


                        </div>
                            );

                });

    }

    onChange(event,input,id){

        let suggestions = [];
        const text = event.target.value;
        let {milestoneAmounts} = this.props;
        milestoneAmounts[id] = {...milestoneAmounts[id],[input]:text};
        this.props.inputChanged(milestoneAmounts);



    }

    render(){

        return(
            <>
                <h5>Campaign Rewards</h5>
                <div className="row">
                    <div className={"col-lg"}>
                        <Radio
                            options = {this.state.options}
                            name = {"rewardOptions"}
                        />
                    </div>
                </div>


                <OptionalField targetName={"rewardOptions"} targetValue={"Product Specific"}>
                        <div className={"row"}>
                            <div className="col-lg-3 mg-t-10 mg-lg-t-0">
                                <Input
                                    type={"text"}
                                    labelText={"SKU"}
                                    name={"sku"}
                                    placeholder={"#"}
                                    className={"form-control"}
                                    number={"false"}
                                    parentname={"targetingForm"}

                                />
                            </div>
                            <div className="col-lg-3 mg-t-10 mg-lg-t-0">
                                <Input
                                    type={"text"}
                                    labelText={"Product Name"}
                                    name={"productName"}
                                    placeholder={"Product Name"}
                                    className={"form-control"}
                                    number={"false"}
                                    parentname={"targetingForm"}

                                />
                            </div>
                            <div className="col-lg-3 mg-t-10 mg-lg-t-0">
                                <Input
                                    type={"text"}
                                    labelText={"Cashback Amount"}
                                    name={"cashbackAmount"}
                                    placeholder={"#"}
                                    className={"form-control"}
                                    number={"true"}
                                    parentname={"targetingForm"}

                                />
                            </div>
                            <div className="col-lg-3 mg-t-10 mg-lg-t-0">
                                <Radio
                                    options = {this.state.cashBackOptions}
                                    name = {"cashBackOptions"}
                                />
                            </div>
                        </div>


                        <div className={"row"}>
                                <div className={"col-lg-6 mg-t-10 mg-lg-t-0"}>
                                    <TextArea
                                        type={"text"}
                                        labelText={"Product Details"}
                                        name={"productDetails"}
                                        placeholder={"Details"}
                                        className={"form-control"}
                                        parentname={this.props.parentname}
                                        rows={3}

                                    />
                                </div>
                        </div>
                    </OptionalField>

                <OptionalField targetName={"rewardOptions"} targetValue={"Milestones"}>
                    {this.genBoxes()}
                    <div className="row">
                        <div className={"col-lg mg-t-10 mg-lg-t-0"} style={{paddingBottom:"20px"}}>
                            <button type="button" className="btn btn-primary" onClick={this.addBox}>{`Add Extra Milestone`}</button>
                        </div>

                    </div>
                </OptionalField>
                <OptionalField targetName={"rewardOptions"} targetValue={"General CB Offer"}>
                    <div className="row">
                        <div className={"col-lg mg-t-10 mg-lg-t-0"} style={{paddingBottom:"20px"}}>
                            <Input
                                type={"text"}
                                labelText={"Cashback Percent"}
                                name={"generalCashbackAmount"}
                                placeholder={"#"}
                                className={"form-control"}
                                number={"true"}
                                parentname={"targetingForm"}
                            />
                        </div>

                    </div>
                </OptionalField>



            </>
        )

    }


}

const mapStateToProps = (state,ownProps) => {

    let form = state.forms.targetingForm;

    let milestoneAmounts = state.forms?.targetingForm?.milestoneList;
    refresh = !refresh;


    return {
        milestoneAmounts:milestoneAmounts|| [],
        refresh
    }

}

const mapDispatchToProps = (dispatch,ownProps) => {
    return {
        inputChanged: (value) => dispatch(inputChanged("targetingForm","milestoneList",value)),


    }
}
export default connect(mapStateToProps,mapDispatchToProps)(CampaignReward)
