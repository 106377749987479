import React, { Component } from "react";
import Sales from "./GoogleSub/Sales"
import Input from "../../Form/Controls/Input";
import DynamicCreative from "./DynamicCreative";

class GoogleQuestions extends Component {
    constructor(props) {
        super(props);

        this.state = {

            categories:[
                {
                    title:"Goal",
                    list:["Sales","Leads","Website Traffic","Product and Brand Consideration","Brand Awareness and Reach","App Promotion","Local Store Visits and Promotions"]
                },


            ],
            selectedOption:""


        }


        this.generateRadioButtons = this.generateRadioButtons.bind(this);
        this.onValueChange = this.onValueChange.bind(this);

    }

    onValueChange(event) {
        this.setState({
            selectedOption: event.target.value
        });
    }

    generateRadioButtons(){

        return this.state.categories.map((value,index)=>{
            return(
                <div className="col-lg mg-t-4 mg-lg-t-0" key={index}>
                    <h3>
                        {value.title}
                    </h3>
                    <br/>
                    {value.list.map((item,i)=>{

                        return(
                            <div className="radio">
                                <label>
                                    <input
                                        type="radio"
                                        value={item}
                                        checked={this.state.selectedOption === item}
                                        onChange={this.onValueChange}
                                    />
                                    &nbsp;&nbsp;{item}
                                </label>
                            </div>
                        )

                    })}

                </div>
            );

        });


    }


    render(){

        let content =<></>;
        switch (this.state.selectedOption){

            case "Sales":
                content =<Sales/>;
                break;



        }

        return(
            <>
                <div className={"row"}>
                    <div className="col-lg mg-t-4 mg-lg-t-0">
                        <DynamicCreative/>
                    </div>
                </div>
                <div className="row">
                    {this.generateRadioButtons()}
                </div>
                <hr/>
                {content}

            </>

        )


    }


}

export default GoogleQuestions;
