import React, {Component} from "react";
import {connect} from "react-redux";
import Cookies from "js-cookie";
import {addCampaign} from "../../../api/api";

class SubmitCampaign extends Component{

    constructor(props) {
        super(props);

    }

    componentDidMount(){
        this.submitCampaign();

    }

    formatDate(date) {
        let d = date ? new Date(date) : new Date(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    async submitCampaign(){
        console.log("HERE");
        const {campaignID,budgetStartDate,budgetEndDate,budgetAmount,offerDescription,offerDetails,imgUrl} = this.props.formData;
        let details = {
            campaignID,
            budgetAmount,
            budgetEndDate: this.formatDate(budgetEndDate),
            offerDetails,
            offerDescription,
            imgUrl
        }
        if(budgetStartDate){
            details = {...details, budgetStartDate:this.formatDate(budgetStartDate)};
        }
        let res =  await addCampaign(this.getParams(),details);
        console.log(res);
        console.log("HERE2");

            let arr =(Cookies.get("campaigns"));
            if(!arr){
                arr = [res]
            }else if(!arr.includes(this.props.id)){
                arr = [...JSON.parse(arr),res];
            }
            Cookies.set("campaigns",arr);


    }



    getParams(){

        const {
            competitorPurchaseNumberOfDays,
            merchantPurchaseNumberOfDays,
            competitorSpendNumberOfDays,
            merchantSpendNumberOfDays,
            merchantSpendDateOptions,
            competitorSpendDateOptions,
            merchantPurchaseDateOptions,
            competitorPurchaseDateOptions,
            campaignID,
            city,
            compCatPurchaseMax,
            compCatPurchaseMin,
            compCatPurchaseRangeOption,
            compCatSpendMax,
            compCatSpendMin,
            compCatSpendRangeOption,
            country,
            gender,
            maximumAge,
            merchPurchaseMax,
            merchPurchaseMin,
            merchPurchaseRangeOption,
            merchSpendMax,
            merchSpendMin,
            merchSpendRangeOption,
            minimumAge,
            province,
            radius,
            targetOption,
            postalCode
        } = this.props.formData
        const {compCatPurchaseEndDate,compCatPurchaseStartDate,compCatSpendEndDate,compCatSpendStartDate,merchPurchaseEndDate,merchPurchaseStartDate,merchSpendEndDate,merchSpendStartDate} = this.props.formData
        const {competitorList} = this.props.formData;
        const competitors = competitorList ? competitorList[0].map(value => value.id) : [];
        console.log(competitorList)
        console.log(competitors)

        const today = new Date();

        const rollbackMS = new Date();
        rollbackMS.setDate(rollbackMS.getDate()- merchantSpendNumberOfDays || 30)

        const rollbackMP = new Date();
        rollbackMP.setDate(rollbackMP.getDate()- merchantPurchaseNumberOfDays || 30)

        const rollbackCP = new Date();
        rollbackCP.setDate(rollbackCP.getDate()- competitorPurchaseNumberOfDays || 30)

        const rollbackCS = new Date();
        rollbackCS.setDate(rollbackCS.getDate()- competitorSpendNumberOfDays || 30)


        const targetMerchantFreqRange = merchantPurchaseDateOptions === "Date Range"
            ?
            this.formatDate(merchSpendStartDate)+"~"+ (this.formatDate(merchSpendEndDate))
            :
            this.formatDate(rollbackMP)+"~"+ (this.formatDate(today));

        const targetMerchantSpendRange = merchantSpendDateOptions === "Date Range"
            ?
            this.formatDate(merchSpendStartDate)+"~"+(this.formatDate(merchSpendEndDate))
            :
            this.formatDate(rollbackMS)+"~"+ (this.formatDate(today));

        const targetCompetitorFreqRange = competitorPurchaseDateOptions === "Date Range"
            ?
            this.formatDate(merchSpendStartDate)+"~"+(this.formatDate(merchSpendEndDate))
            :
            this.formatDate(rollbackCP)+"~"+ (this.formatDate(today));

        const targetCompetitorSpendRange = competitorSpendDateOptions === "Date Range"
            ?
            this.formatDate(merchSpendStartDate)+"~"+(this.formatDate(merchSpendEndDate))
            :
            this.formatDate(rollbackCS)+"~"+ (this.formatDate(today));


       return {
            targetMerchantFreqRange,
            targetMerchantSpendRange,
            "targetCompetitors": competitors || [],
            "targetMerchant": this.props.merchantId,
            targetCompetitorFreqRange,
            targetCompetitorSpendRange,
            "minUserAge": minimumAge || 0,
            "maxUserAge": maximumAge || 100,
            "targetMerchantSpendMinimum": merchSpendMin || '0',
            "targetMerchantSpendMaximum": merchSpendMax || '1000000',
            "targetCompetitorFreqMinimum": compCatPurchaseMin || 0,
            "targetCompetitorFreqMaximum": compCatPurchaseMax ||1000000,
            "targetMerchantFreqMinimum": merchPurchaseMin || '0',
            "targetMerchantFreqMaximum": merchPurchaseMax || '1000000',
            "targetCompetitorSpendMinimum": compCatSpendMin || '0',
            "targetCompetitorSpendMaximum": compCatSpendMax || '1000000',
            "userGender":gender === "Male" ? "M" : gender === "Female" ? "F" : gender === "Other" ? "O" : ["M","F"],
            "country" : "Canada",
            "province" : province === "All" ? ["Alberta","British Columbia","Manitoba","New Brunswick","Newfoundland and Labrador","Northwest Territories","Nova Scotia","Nunavut","Ontario","Prince Edward Island","Quebec","Saskatchewan","Yukon"] : province,
            postalCode,
            radius : radius || 0
        };
    }


    render() {
        return (
            <>
                <h3>Thanks for launching the campaign.</h3>
            </>
        );
    }


}
const mapStateToProps = (state,ownProps) => {

    let id = state.forms?.targetingForm?.campaignID;
    let formData = state.forms?.targetingForm;
    let merchantId = state.user.id || Cookies.get("merchant");;



    return {
        id,
        formData,
        merchantId
    }

}
export default connect(mapStateToProps)(SubmitCampaign);