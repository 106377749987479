import { combineReducers } from 'redux';
import formReducer from "./form/reducer"
import userReducer from "./user/Reducer"


const rootReducer = combineReducers({

    forms:formReducer,
    user:userReducer

});

export default rootReducer;