import React, {Component} from "react"
import CheckBox from "./CheckBox";
import Input from "./Input";
import Radio from "./Radio";
import DropDown from "./DropDown";
import DatePicker from "./DatePicker";

class DynamicForm extends Component{

    constructor(props) {
        super(props);
        this.state = {
            components:[
                CheckBox,
                Input,
                Radio,
                DropDown,
                DatePicker

            ]
        }

        this.addNameToChildren = this.addNameToChildren.bind(this);

    }




    addNameToChildren(children){
        if(children != null) {
            return React.Children.map(children, (child) => {

                if (React.isValidElement(child)) {

                    let newChildren = this.addNameToChildren(child.props.children);
                     for(let index in this.state.components){

                        let component = this.state.components[index]

                        if(child.type === component) {
                            return React.cloneElement(child, {parentname: this.props.name, children: newChildren});
                        }

                    }

                    return React.cloneElement(child, {children:newChildren,parentname: this.props.name});
                }
                return child;

            });
        }
        return [];

    }


    render(){

        let children = this.addNameToChildren(this.props.children);

        return(
            <>
                {children}
            </>
        )

    }



}
export default DynamicForm
