import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams
} from "react-router-dom";
import Graphs from "./Graphs";
import ViewCampaigns from "./ViewCampaigns";

function ViewCampaignIndex(){

    let match = useRouteMatch();

    return (
        <>
            <Switch>
                <Route path={`${match.path}/:id`}>
                   <Graphs/>
                </Route>
                <Route path={match.path}>
                    <ViewCampaigns/>
                </Route>
            </Switch>
        </>
    );

}
export default ViewCampaignIndex