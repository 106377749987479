import React, {Component} from "react";

class HorizontalRule extends Component{

    render(){

        return(
            <div style={styles}>

            </div>
        );

    }


}

const styles = {

    border:"0px",
    borderTop:"1px",
    height:"5px",
    borderStyle: "solid",
    marginTop:"20px",
    marginBottom:"20px"

}

export default HorizontalRule;