import React, {Component} from "react";
import Input from "../../Form/Controls/Input";
import DatePicker from "../../Form/Controls/DatePicker";
import TextArea from "../../Form/Controls/TextArea";
import ImageUploading from 'react-images-uploading';
import { getImgUrl } from "../../../api/api";
import {inputChanged} from "../../../Redux/form/action";
import {connect} from "react-redux";

class CampaignDetails extends Component{

    constructor() {
        super();

        this.state = {images:[]}
        this.onChange = this.onChange.bind(this);
    }

    async onChange(imageList, addUpdateIndex){
       
        console.log(imageList, addUpdateIndex);
        const url = await getImgUrl(imageList[0]);
        this.props.inputChanged(url.filename)
        this.setState({images:imageList})
      };
    

    render() {

        return(
            <>
                <h5>Campaign Details</h5>

                <div className={"row"}>
                    <div className={"col-lg-4 mg-t-10 mg-lg-t-0"}>
                        <Input
                            type={"text"}
                            labelText={"Budget"}
                            name={"budgetAmount"}
                            placeholder={"$"}
                            className={"form-control"}
                            number={"true"}
                            parentname={this.props.parentname}

                        />

                    </div>

                    <div className="col-lg-2 mg-t-10 mg-lg-t-0">
                        <DatePicker
                            labelText={"Start Date"}
                            name={"budgetStartDate"}
                            className={"form-control"}
                            parentname={this.props.parentname}


                        />

                    </div>
                    <div className="col-lg-4 mg-t-10 mg-lg-t-0">
                        <DatePicker
                            labelText={"End Date"}
                            name={"budgetEndDate"}
                            className={"form-control"}
                            parentname={this.props.parentname}

                        />

                    </div>

                </div>

                <div className={"row"}>
                    <div className={"col-lg-6 mg-t-10 mg-lg-t-0"}>
                        <TextArea
                            type={"text"}
                            labelText={"Offer Description"}
                            name={"offerDescription"}
                            placeholder={"Description"}
                            className={"form-control"}
                            parentname={this.props.parentname}
                            rows={3}

                        />
                    </div>
                    <div className={"col-lg-6 mg-t-10 mg-lg-t-0"}>
                        <TextArea
                            type={"text"}
                            labelText={"Offer Details"}
                            name={"offerDetails"}
                            placeholder={"Details"}
                            className={"form-control"}
                            parentname={this.props.parentname}
                            rows={3}

                        />
                    </div>
                </div>

                <div className={"row"}>
                <div className={"col-lg-6 mg-t-10 mg-lg-t-0"}>
                    <h4>Image Upload</h4>
                    <ImageUploading
                        value={this.state.images}
                        onChange={this.onChange}
                        dataURLKey="data_url"
                    >
                         {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          // write your building UI
          <div className="upload__image-wrapper">
            <button
              style={isDragging ? { color: 'red' } : undefined}
              onClick={onImageUpload}
              {...dragProps}
            >
              Click or Drop here
            </button>
            &nbsp;
            <button onClick={onImageRemoveAll}>Remove all images</button>
            {imageList.map((image, index) => (
              <div key={index} className="image-item">
                <img src={image['data_url']} alt="" width="100" />
                <div className="image-item__btn-wrapper">
                  <button onClick={() => onImageUpdate(index)}>Update</button>
                  <button onClick={() => onImageRemove(index)}>Remove</button>
                </div>
              </div>
            ))}
          </div>
        )}
                    </ImageUploading>
                      
                    </div>
                </div>
            


            </>
        )
    }


}
const mapStateToProps = (state,ownProps) => {


    return {
      
    }

}

const mapDispatchToProps = (dispatch,ownProps) => {
    return {
        inputChanged: (value) => dispatch(inputChanged("targetingForm","imgUrl",value)),


    }
}
export default connect(mapStateToProps,mapDispatchToProps)(CampaignDetails)