import React,{Component} from "react";
import TextArea from "../../Form/Controls/TextArea";

class DynamicCreative extends Component{

    constructor(props) {
        super(props);
        this.state = {
            files: []
        }
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event) {
        let {files} = this.state
        files = [...files, URL.createObjectURL(event.target.files[0])]
        this.setState({
            files
        })
    }

    genImages(){

        const {files} = this.state;

        return files.map((file,key) =>  <div className="col-lg-3 mg-t-4 mg-lg-t-0"><img style={{width:"100%"}} src={file} key={key}/></div>);


    }

    render(){
        return (
            <>
                <div className={"row"}>
                    <div className="col-lg mg-t-4 mg-lg-t-0">
                        <h3>Creative Upload</h3>
                    </div>
                </div>
                <div className={"row"}>
                    <div className="col-lg mg-t-4 mg-lg-t-0">
                        <input type="file" onChange={this.handleChange}/>
                    </div>
                </div>
                <hr/>
                <div className={"row"}>
                    {this.genImages()}
                </div>

                <div className={"row"}>
                    <div className="col-lg mg-t-4 mg-lg-t-0">
                        <TextArea
                            type={"text"}
                            labelText={"Headlines"}
                            name={"offerDescription"}
                            placeholder={"If Applicable"}
                            className={"form-control"}
                            parentname={this.props.parentname}
                            rows={3}

                        />
                    </div>
                </div>
                <div className={"row"}>
                    <div className="col-lg mg-t-4 mg-lg-t-0">
                        <TextArea
                            type={"text"}
                            labelText={"Long Headlines"}
                            name={"offerDescription"}
                            placeholder={"If Applicable"}
                            className={"form-control"}
                            parentname={this.props.parentname}
                            rows={3}

                        />
                    </div>
                </div>
                <hr/>

            </>
        );


    }


}
export default DynamicCreative;