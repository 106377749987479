import React, { Component } from "react";
import { getNewMetaBaseQuestion,getMetaBaseQuestion } from "../../../api/api";


class MetabaseGraph extends Component {
    constructor(props) {
        super(props);
        this.state = {
            iframeUrl:"",
            lastValue:""

        }
        this.getLink = this.getLink.bind(this);
        this.getLink();


    }

    getLink() {
        if (JSON.stringify(this.props.params) !== this.state.lastValue) {
            this.setState({lastValue:JSON.stringify(this.props.params)})
            let params = {};
            if(this.props.params)
            {
                params = {question: this.props.question, params: this.props.params};

            }
            else
            {
                params = {question: this.props.question};
            }
            let func = getMetaBaseQuestion;
            if(this.props.source){

                func = getNewMetaBaseQuestion;
                params = {...params,source:this.props.source};

            }
            func(params).then(response => {
                this.setState({iframeUrl: response});
            })
                .catch(error => {
                    console.log('error :>>> ', error);
                });

        }
    }


    render(){


            this.getLink();


        return(

            <div>
                <iframe
                    src={this.state.iframeUrl}
                    frameBorder={0}
                    width={"100%"}
                    height={600}
                    allowTransparency
                />
            </div>

        )


    }


}

export default MetabaseGraph;
